import { Client } from "./Client";

export interface BusinessCardOrder {
    id?: number | null;
    quantity: number;
    priceEuro: number;
    shippingAddressLine1: string | undefined;
    shippingAddressLine2?: string;
    shippingCity?: string;
    shippingPostcode: string | undefined;
    paymentStatus: string;
    orderStatus: string;
    cancelled: boolean;
    client?: Client;
    trackingNumber?: string;
  }

  export enum OrderStatus {
    DRAFT="Draft",
    ORDERED="Ordered",
    ACCEPTED="Accepted",
    PRINTING = "Printing",
    SENT = "Sent",
    COMPLETED = "Completed"
  }
