import React, { ChangeEvent, useEffect, useState } from "react";
import "./BusinessCardOrderList.css";
import { BusinessCardOrder, OrderStatus } from "../../Types/BusinessCardOrder";
import Modal from "@/components/Modal/Modal";
import { AxiosError } from "axios";
import BusinessCardOrderItem from "./BusinessCardOrderItem";
import {
  delWithAuthHeader,
  getWithAuthHeader,
  patchWithAuthHeader,
  postWithAuthHeader,
} from "@/utils/apiUtils";
import Button from "@/components/common/Button";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import { ActionProps } from "../../Types/ActionProps";
import { ButtonType } from "../../Types/ButtonType";
import { PaymentStatus } from "../../Types/Status";
import { getKeyFromValue } from "@/components/common/Select/SelectEnum";
import { isSuperuser } from "@/utils/authUtils";
import BusinessCardOrderTable from "./BusinessCardOrderTable";
import BusinessCardOrderListMobile from "./BusinessCardOrderListMobile";
import { isMobile } from "@/utils/globalFuncs";
import { useLocation } from "react-router-dom";
import NoAvailableDataComponent from "../NoAvailableDataComponent";

const APPEND_URL = `/${isSuperuser() ? "super" : "client"}/businesscardorders`;

interface IMyProps {
  items: BusinessCardOrder[] | undefined;
  refresh: Function;
}

const initBusinessCardOrder: BusinessCardOrder = {
  quantity: 100,
  priceEuro: 22.0,
  paymentStatus: getKeyFromValue(PaymentStatus, PaymentStatus.UNPAID),
  orderStatus: getKeyFromValue(OrderStatus, OrderStatus.DRAFT),
  cancelled: false,
  shippingAddressLine1: undefined,
  shippingPostcode: undefined,
};

const BusinessCardOrderList: React.FC<IMyProps> = (props: IMyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [businessCardOrder, setBusinessCardOrder] = useState<BusinessCardOrder>(
    initBusinessCardOrder
  );
  const [message, setMessage] = useState<Message>();
  const [doCreate, setDoCreate] = useState(false);
  const [doUpdate, setDoUpdate] = useState(false);
  const [doDelete, setDoDelete] = useState(false);
  const myParam = useLocation().search;
  const [id] = useState<string | null>(new URLSearchParams(myParam).get("id"));

  useEffect(() => {
    if (id) {
      getWithAuthHeader(`${APPEND_URL}/${id}`)
        .then((response) => {
          var bco: BusinessCardOrder = response.data.data;
          console.log(bco);
          setBusinessCardOrder(response.data.data);
          if (!bco.cancelled) {
            if (bco.paymentStatus !== PaymentStatus.UNPAID) {
              setDoUpdate(true);
            }
            if (bco.orderStatus !== OrderStatus.COMPLETED) {
              setDoDelete(true);
            }
          }

          openModal();
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  }, [id]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setBusinessCardOrder(initBusinessCardOrder);
    setIsOpen(false);
    setDoCreate(false);
    setDoDelete(false);
    setDoUpdate(false);
    setMessage(undefined);
  };

  const handleSelect = (item: BusinessCardOrder) => {
    console.log(item);
    setBusinessCardOrder(item);
    openModal();
  };

  const handleNew = (businessCardOrder: BusinessCardOrder) => {
    setBusinessCardOrder(initBusinessCardOrder);
    openModal();
  };

  const handleDelete = async () => {
    if (businessCardOrder && businessCardOrder.id) {
      await delWithAuthHeader(`${APPEND_URL}/cancel/${businessCardOrder.id}`)
        .then((response) => {
          setBusinessCardOrder(response.data.data);
          closeModal();
          props.refresh(false, businessCardOrder.id);
          setMessage({
            message: response?.data.message,
            severity: Severity.Info,
          });
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const deleteAction: ActionProps = {
    name: "Cancel Order",
    method: handleDelete,
    buttonType: ButtonType.delete,
    disabled: businessCardOrder.cancelled,
  };

  const handleCreate = async () => {
    if (businessCardOrder.quantity < 40) {
      setMessage({
        message:
          "Minimum order is 40 cards. Please add more than the minimum printable quantity.",
        severity: Severity.Error,
      });
      return;
    }

    if (businessCardOrder.priceEuro < 20) {
      setMessage({
        message:
          "Minimum spend is 20€. Please update the quantity to reach minimum printable price.",
        severity: Severity.Error,
      });
      return;
    }

    if (businessCardOrder) {
      await postWithAuthHeader(`${APPEND_URL}`, businessCardOrder)
        .then((response) => {
          props.refresh(true);
          setBusinessCardOrder(response.data.data);
          setMessage({
            message: response?.data.message,
            severity: Severity.Info,
          });
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const createAction: ActionProps = {
    name: "Create",
    method: handleCreate,
    buttonType: ButtonType.primary,
    disabled: businessCardOrder.cancelled || businessCardOrder.id != null,
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setBusinessCardOrder({
      ...businessCardOrder,
      [e.target.name]:
        e.target.name === "paymentStatus"
          ? getKeyFromValue(PaymentStatus, e.target.value)
          : e.target.name === "orderStatus"
          ? getKeyFromValue(OrderStatus, e.target.value)
          : e.target.value,
    });
  };

  const handleSelectChange = (name: string, value: string) => {
    setBusinessCardOrder({
      ...businessCardOrder,
      [name]: value,
    });
  };

  const handleQuantityChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    var quantity = Number(e.target.value);
    var partial = {
      quantity: quantity,
      priceEuro: Number(Number(quantity * 0.2 + 2).toFixed(2)),
    };

    setBusinessCardOrder({
      ...businessCardOrder,
      ...partial,
    });
  };

  const handleUpdate = async () => {
    console.log(businessCardOrder);
    if (businessCardOrder && businessCardOrder.id) {
      await patchWithAuthHeader(
        `${APPEND_URL}${isSuperuser() ? "/orderStatus" : ""}`,
        businessCardOrder
      )
        .then((response) => {
          props.refresh(true);
          setMessage({
            message: response?.data.message,
            severity: Severity.Info,
          });
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const updateAction: ActionProps = {
    name: "Update",
    method: handleUpdate,
    buttonType: ButtonType.primary,
    disabled: businessCardOrder.cancelled || businessCardOrder.id == null,
  };

  const actions: ActionProps[] = [];

  if (doCreate && businessCardOrder.id == null) {
    actions.push(createAction);
  }

  if (!businessCardOrder.cancelled && businessCardOrder.id != null) {
    if (businessCardOrder.orderStatus !== OrderStatus.COMPLETED) {
      if (doUpdate) {
        actions.push(updateAction);
      }
    }
    if (doDelete) {
      actions.push(deleteAction);
    }
  }

  return (
    <div className="management-container">
      <div className="add-new-button">
        <p>
          <b>{props.items && props.items.length} </b>
          {props?.items?.length === 1 ? "Order" : "Orders"}
        </p>
        <Button
          onClick={() => {
            handleNew(businessCardOrder);
            setDoCreate(true);
          }}
        >
          Add New Order
        </Button>
      </div>
      {props.items && props.items?.length > 0 ? (
        isMobile() ? (
          <BusinessCardOrderListMobile
            details={props.items ?? []}
            handleSelect={handleSelect}
            setDoUpdate={setDoUpdate}
            setDoDelete={setDoDelete}
          ></BusinessCardOrderListMobile>
        ) : (
          <BusinessCardOrderTable
            details={props.items ?? []}
            handleSelect={handleSelect}
            setDoUpdate={setDoUpdate}
            setDoDelete={setDoDelete}
          ></BusinessCardOrderTable>
        )
      ) : (
        <NoAvailableDataComponent />
      )}
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        actions={actions}
        title={"Business Card Order Details"}
        message={message}
      >
        {businessCardOrder && (
          <BusinessCardOrderItem
            item={businessCardOrder}
            handleInputChange={handleInputChange}
            handleQuantityChange={handleQuantityChange}
            handleSelectChange={handleSelectChange}
          />
        )}
      </Modal>
    </div>
  );
};

export default BusinessCardOrderList;
