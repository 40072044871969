import React, { ChangeEvent, useState } from "react";
import "./TariffList.css";
import { Tariff } from "../../Types/Tariff";
import Modal from "@/components/Modal/Modal";
import { AxiosError } from "axios";
import TariffItem from "./TariffItem";
import {
  delWithAuthHeader,
  patchWithAuthHeader,
  postWithAuthHeader,
} from "@/utils/apiUtils";
import Button from "@/components/common/Button";
import { ActionProps } from "../../Types/ActionProps";
import { ButtonType } from "../../Types/ButtonType";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import TariffTable from "./TariffTable";
import TariffListMobile from "./TariffListMobile";
import { isMobile } from "@/utils/globalFuncs";
import NoAvailableDataComponent from "../NoAvailableDataComponent";
import { Location } from "../../Types/Location";

const APPEND_URL = "/client/tariffs";

interface IMyProps {
  items: Tariff[] | undefined;
  refresh: Function;
  fromLocations?: Location[];
  toLocations?: Location[];
  setParentMessage?: Function;
}

const initTariff = {
  priceEuro: undefined,
  fromLocation: null,
  toLocation: null,
};

const TariffList: React.FC<IMyProps> = (props: IMyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tariff, setTariff] = useState<Tariff>(initTariff);
  const [modalMessage, setModalMessage] = useState<Message | undefined>({});
  const [doCreate, setDoCreate] = useState(false);
  const [doUpdate, setDoUpdate] = useState(false);
  const [doDelete, setDoDelete] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    setModalMessage({}); // Clear message
  };

  const closeModal = () => {
    setTariff(initTariff);
    setDoCreate(false);
    setDoDelete(false);
    setDoUpdate(false);
    setIsOpen(false);
  };

  const handleSelect = (tariff: Tariff) => {
    setTariff(tariff);
    openModal();
  };

  const handleFromOptionChange = (e: number) => {
    console.log(e);
    var fromLocationLocal = props.fromLocations?.filter(
      (location) => location.id === Number(e)
    )[0];

    setTariff({
      ...tariff,
      fromLocation: fromLocationLocal ? fromLocationLocal : null,
    });
  };

  const handleToOptionChange = (e: number) => {
    console.log(e);
    var toLocationLocal = props.toLocations?.filter(
      (location) => location.id === Number(e)
    )[0];

    setTariff({
      ...tariff,
      toLocation: toLocationLocal ? toLocationLocal : null,
    });
  };

  const handleDelete = async () => {
    if (tariff) {
      await delWithAuthHeader(`${APPEND_URL}/${tariff.id}`)
        .then((response) => {
          if (response.data.data === true) {
            closeModal();
            props.refresh(false, tariff.id);
            if (props.setParentMessage) {
              props.setParentMessage({
                message: `Tariff from '${tariff.fromLocation?.location_name}' to '${tariff.toLocation?.location_name}' deleted successfuly`,
                severity: Severity.Info,
              }); // Clear parent message
            }
          }
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const deleteAction: ActionProps = {
    name: "Delete",
    method: handleDelete,
    buttonType: ButtonType.delete,
  };

  const handleUpdate = async () => {
    console.log(tariff);
    if (tariff && tariff.id) {
      await patchWithAuthHeader(`${APPEND_URL}`, tariff)
        .then((response) => {
          props.refresh(true);
          setModalMessage({
            message: response?.data.message,
            severity: Severity.Info,
          });
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const updateAction: ActionProps = {
    name: "Update",
    method: handleUpdate,
    buttonType: ButtonType.primary,
  };

  const handleCreate = async () => {
    console.log(tariff);
    if (
      tariff &&
      tariff.fromLocation !== null &&
      tariff.toLocation !== null &&
      tariff.priceEuro !== undefined &&
      tariff.priceEuro > 0
    ) {
      await postWithAuthHeader(`${APPEND_URL}`, tariff)
        .then((response) => {
          props.refresh(true);
          setTariff(response.data.data);
          setModalMessage({
            message: response?.data.message,
            severity: Severity.Info,
          });
          setDoCreate(false);
          setDoUpdate(true);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const createAction: ActionProps = {
    name: "Create",
    method: handleCreate,
    buttonType: ButtonType.primary,
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setTariff({
      ...tariff,
      [e.target.name]: e.target.value,
    });
  };

  const actions: ActionProps[] = [];
  if (doCreate) {
    actions.push(createAction);
  }
  if (doUpdate) {
    actions.push(updateAction);
  }
  if (doDelete) {
    actions.push(deleteAction);
  }

  return (
    <div>
      <div className="add-new-button">
        <p>
          <b>{props.items && props.items.length} </b>
          {props?.items?.length === 1 ? "Tariff" : "Tariffs"}
        </p>
        <Button
          onClick={() => {
            setDoCreate(true);
            handleSelect(tariff);
          }}
        >
          Add Tariff
        </Button>
      </div>
      {props.items && props.items?.length > 0 ? (
        isMobile() ? (
          <TariffListMobile
            details={props.items ?? []}
            handleSelect={handleSelect}
            setDoUpdate={setDoUpdate}
            setDoDelete={setDoDelete}
          ></TariffListMobile>
        ) : (
          <TariffTable
            details={props.items ?? []}
            handleSelect={handleSelect}
            setDoUpdate={setDoUpdate}
            setDoDelete={setDoDelete}
          ></TariffTable>
        )
      ) : (
        <NoAvailableDataComponent />
      )}

      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        actions={actions}
        title={"Tariff Details"}
        message={modalMessage}
      >
        {tariff && (
          <TariffItem
            item={tariff}
            handleInputChange={handleInputChange}
            fromLocation={props.fromLocations}
            handleFromLocationChange={handleFromOptionChange}
            toLocation={props.toLocations}
            handleToLocationChange={handleToOptionChange}
            doUpdate={doUpdate}
          />
        )}
      </Modal>
    </div>
  );
};

export default TariffList;
