import { isMobile } from "@/utils/globalFuncs";
import Button from "@common/Button";
import { RiCloseLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const ScheduleDetails: React.FC<{
  isScheduleOpen?: boolean;
  setScheduleOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isScheduleOpen, setScheduleOpen }) => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  return (
    <div
      className={`scheduler-container ${
        isScheduleOpen ? "mobile" : isMobile() ? "hidden" : ""
      }`}
    >
      <RiCloseLine
        style={{ marginBottom: "-3px" }}
        className="close-icon"
        onClick={() => (setScheduleOpen ? setScheduleOpen(false) : {})}
      />
      <div className="schedule-title">Your Schedule</div>
      <div className="schedule-info">
        Keep your schedule flexible and organized. Start managing your time
        today!
      </div>
      <div className="statistics">
        <div className="statistics-title">Your Statistics:</div>
        <div className="statistics-info">
          <div className="statistics-info-detail">
            <div>
              <b>Total rides this week:</b>
            </div>
            <div> ...</div>
          </div>
          <div className="statistics-info-detail">
            <div>
              <b>Hours worked:</b>{" "}
            </div>
            <div> ...</div>
          </div>
          <div className="statistics-info-detail">
            <div>
              <b>Total earnings:</b>
            </div>
            <div> ...</div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <Button type="decline">
          <b>Calendar view</b>
        </Button>
      </div>

      <div className="nav-item dropdown">
        {/* eslint-disable-next-line */}

        <div
          className="dropdown-menu show"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <p className="links-section-title"> Management pages</p>
          <Link
            to="/client/client-management"
            className={`dropdown-item ${
              isActive("/client/client-management") ? "active" : ""
            }`}
          >
            Client
          </Link>
          <Link
            to="/client/ride-management"
            className={`dropdown-item ${
              isActive("/client/ride-management") ? "active" : ""
            }`}
          >
            Rides
          </Link>
          <Link
            to="/client/fleet-management"
            className={`dropdown-item ${
              isActive("/client/fleet-management") ? "active" : ""
            }`}
          >
            Fleet
          </Link>
          <Link
            to="/client/tariff-management"
            className={`dropdown-item ${
              isActive("/client/tariff-management") ? "active" : ""
            }`}
          >
            {" "}
            Tariffs
          </Link>
          <Link
            to="/client/business-card-order"
            className={`dropdown-item ${
              isActive("/client/business-card-orders") ? "active" : ""
            }`}
          >
            {" "}
            Business Card Orders
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ScheduleDetails;
