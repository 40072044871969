import React, { ChangeEventHandler, useEffect } from "react";
import "./VehicleItem.css";
import { Driver, Vehicle } from "../../Types/Vehicle";
import { renderNumberOptions } from "@/utils/formatUtils";
import SelectValue from "@/components/common/Select/SelectValue";

interface IMyProps {
  item: Vehicle;
  handleInputChange: ChangeEventHandler;
  handleDriverOptionChange: Function;
  drivers: Driver[] | undefined;
  handleInputSelectChange: (e: number, label: string) => void;
}

const VehicleItem: React.FC<IMyProps> = (props: IMyProps) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  var driverOptions = [{ value: 0, label: "No default" }];

  if (props.drivers) {
    driverOptions = [
      ...driverOptions,
      ...props.drivers.map((driver) => ({
        value: driver.id ? driver.id : 0,
        label: driver.firstName + " " + driver.lastName,
      })),
    ];
  }

  var initOption;

  if (props.item.driver && props.item.driver.id) {
    initOption = {
      value: props.item.driver.id ? props.item.driver.id : 0,
      label: props.item.driver.firstName + " " + props.item.driver.lastName,
    };
  }

  return (
    <div>
      <div className="div-container">
        <div className="div-content">
          <div>
            <p>
              Vehicle Id: <b>{props.item.id}</b> / Licence Plate:{" "}
              <b>{props.item.licencePlate}</b>
            </p>
          </div>
          <label className="bookatrip">Licence Plate</label>
          <input
            type="text"
            name="licencePlate"
            value={props.item.licencePlate}
            onChange={props.handleInputChange}
          />
          <label className="bookatrip">Make</label>
          <input
            type="text"
            name="make"
            value={props.item.make}
            onChange={props.handleInputChange}
          />
          <label className="bookatrip">Model</label>
          <input
            type="text"
            name="model"
            value={props.item.model}
            onChange={props.handleInputChange}
          />
          <div>
            <label>Max People:</label>
            <SelectValue
              initOption={
                props.item.maxPeople
                  ? {
                      value: props.item.maxPeople,
                      label: props.item.maxPeople.toString(),
                    }
                  : undefined
              }
              options={renderNumberOptions(100, 1, 1).map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              onSelectChange={(e) =>
                props.handleInputSelectChange(e, "maxPeople")
              }
            />
          </div>
          <div>
            <label>Max Luggage:</label>
            <SelectValue
              initOption={
                props.item.maxLuggage
                  ? {
                      value: props.item.maxLuggage,
                      label: props.item.maxLuggage.toString(),
                    }
                  : undefined
              }
              options={renderNumberOptions(100).map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              onSelectChange={(e) =>
                props.handleInputSelectChange(e, "maxLuggage")
              }
            />
          </div>
          <div>
            <label className="bookatrip">
              <b>DEFAULT DRIVER</b>
            </label>
            <SelectValue
              options={driverOptions}
              onSelectChange={(e) => props.handleDriverOptionChange(e)}
              initOption={initOption}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleItem;
