import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import "../Tariff/TableListMobile.css";
import { BusinessCardOrder } from "../../Types/BusinessCardOrder";

interface BusinessCardListMobileProps {
  details: BusinessCardOrder[];
  handleSelect: Function;
  setDoUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setDoDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const BusinessCardOrderListMobile: React.FC<BusinessCardListMobileProps> = (
  tableData
) => {
  return (
    <div className="table-list-container-mobile">
      <h4 className="table-title">
        <b>Business Cards</b>
      </h4>
      <div className="table-list-mobile">
        {tableData.details.map((props: BusinessCardOrder) => {
          const handleSelect = (e: { preventDefault: () => void }) => {
            e.preventDefault();
            tableData.handleSelect(props);
          };
          return (
            <div className="table-list-item-container" key={props.id}>
              <div className="table-list-item">
                <div>
                <p>
                <b>{props.client?.companyName}</b>
                  </p>
                  </div>
                  <div className="item-justify-end"></div>
                  <div>
                  <p className="list-item-label">Cards Quantity</p>
                  <p>
                    <b>{props.quantity}</b>
                  </p>
                </div>
                <div className="item-justify-end">
                  <p className="list-item-label">Price</p>
                  <p>
                    <b>{props.priceEuro} €</b>
                  </p>
                </div>
                <div>
                  <p className="list-item-label">Order Status</p>
                  <p>
                    <b>{props.orderStatus}</b>
                  </p>
                </div>
                <div className="item-justify-end">
                  <p className="list-item-label"></p>
                  <p className="type-UNCONFIRMED">
                    <b> {props.cancelled ? "CANCELLED" : ""}</b>
                  </p>
                </div>
              </div>
              <div className="table-list-action-buttons">
                <div>
                  <ModeEditIcon
                    onClick={(e) => {
                      tableData.setDoUpdate(true);
                      handleSelect(e);
                    }}
                  ></ModeEditIcon>
                </div>
                <div>
                  <DeleteIcon
                    onClick={(e) => {
                      tableData.setDoDelete(true);
                      handleSelect(e);
                    }}
                  ></DeleteIcon>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BusinessCardOrderListMobile;
