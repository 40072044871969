import { FC, useState, useEffect, useRef, ReactNode } from "react";
import Select from "react-select";
import "./SelectValue.css";

export interface Option {
  value?: number;
  label: string;
}

interface SelectValueProps {
  noOptionsMessage?: ((obj: { inputValue: string; }) => ReactNode) | undefined;
  placeholder?: string;
  options?: Option[];
  onSelectChange: (id: number) => void;
  resetValue?: boolean; // New prop to trigger reset
  initOption?: Option;
  isDisabled?: boolean;
}

const SelectValue: FC<SelectValueProps> = (props: SelectValueProps) => {
  const [selectedOption, setSelectedOption] = useState<
    Option | undefined | null
  >(props.initOption ? props.initOption : null);
  const [menuPlacement, setMenuPlacement] = useState<"top" | "bottom">(
    "bottom"
  );

  const selectRef = useRef<any>(null);

  // Check available space and set dropdown position
  useEffect(() => {
    const updateMenuPlacement = () => {
      if (selectRef.current) {
        const selectBox = selectRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - selectBox.bottom;
        const spaceAbove = selectBox.top;

        // If not enough space below, open above
        setMenuPlacement(
          spaceBelow < 200 && spaceAbove > spaceBelow ? "top" : "bottom"
        );
      }
    };

    updateMenuPlacement();
    window.addEventListener("scroll", updateMenuPlacement);
    return () => window.removeEventListener("scroll", updateMenuPlacement);
  }, []);

  // Reset selected option when resetValue changes
  useEffect(() => {
    setSelectedOption(props.initOption);
    if (props.resetValue) {
      setSelectedOption(null);
    }
  }, [props.resetValue, props.initOption]);

  const handleSelectChange = (selectedOption: Option | null) => {
    setSelectedOption(selectedOption);
    if (selectedOption && selectedOption.value) {
      props.onSelectChange(selectedOption.value);
    }
  };

  return (
    <div ref={selectRef}>
      <Select
        noOptionsMessage={props.noOptionsMessage}
        className="react-select-container"
        classNamePrefix="react-select"
        options={props.options}
        value={selectedOption}
        onChange={handleSelectChange}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999, maxHeight: "250px" }),
        }}
        isDisabled={props.isDisabled}
        menuPlacement={menuPlacement} // Dynamically set menu placement
        {...(props.placeholder && { placeholder: props.placeholder })}
      />
    </div>
  );
};

export default SelectValue;
