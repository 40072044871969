export enum Status {
    COMPLETED= "Completed",
    ACCEPTED= "Accepted",
    UNCONFIRMED= "Unconfirmed",
    CONFIRMED = "Confirmed",
    REJECTED = "Rejected"
  }


export enum PaymentStatus {
    UNPAID="Unpaid",
    BANK_TRANSFER_UNCONFIRMED="Bank Transfer - Unconfirmed",
    BANK_TRANSFER_SENT="Sent by Bank Transfer",
    BANK_TRANSFER_CONFIRMED="Bank Transfer - Confirmed",
    CASH_TRANSFER_UNCONFIRMED="Cash Transfer - Unconfirmed",
    CASH_TRANSFER_SENT="Sent by Cash",
    CASH_TRANSFER_CONFIRMED="Cash Transfer - Confirmed",
    PAID="Paid"
  }

  export enum CustomerPaymentStatusOptions {
    UNPAID="Unpaid",
    BANK_TRANSFER_SENT="Sent by Bank Transfer",
    CASH_TRANSFER_SENT="Sent by Cash"
  }