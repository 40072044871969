import { getMessageContent } from "@/components/Account/accountUtilities";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { getWithAuthHeader } from "@/utils/apiUtils";
import { Tariff } from "../Types/Tariff";
import TariffList from "./Tariff/TariffList";
import LocationList from "./Location/LocationList";
import Button from "@/components/common/Button";
import { Location } from "../Types/Location";

const APPEND_URL = "/client";

const typeTariffs = "tariffs";
const typeLocations = "locations";

const App: React.FC = () => {
  const [message, setMessage] = useState<Message | null>(null);
  const [tariffs, setTariffs] = useState<Tariff[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [activeTab, setActiveTab] = useState<"tariffs" | "locations">(
    "tariffs"
  );
  // const [isSmallScreen
  // , setIsSmallScreen
  // ] = useState<boolean>(true);

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  }

  const refresh = (type: string) => {
    getWithAuthHeader(`${APPEND_URL}/${type}`, false)
      .then((response) => {
        if (type === typeTariffs) {
          setTariffs(response.data.data);
        }
        if (type === typeLocations) {
          setLocations(response.data.data);
        }
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          setMessage({
            message: error.response?.data.message,
            severity: Severity.Error,
          });
        }
      });
  };

  useEffect(() => {
    refresh(typeTariffs);
    refresh(typeLocations);

    // const handleResize = () => {
    //   setIsSmallScreen(window.innerWidth < 768); // Adjust breakpoint as needed
    // };

    // window.addEventListener("resize", handleResize);
    // handleResize(); // Initialize on mount

    // return () => window.removeEventListener("resize", handleResize);
  }, []);

  const refreshTariffs = (refreshAll: boolean, removeId?: number) => {
    if (refreshAll) {
      refresh(typeTariffs);
    } else if (removeId) {
      setTariffs(tariffs.filter((tariff) => tariff.id !== removeId));
    }
  };

  const refreshLocations = (refreshAll: boolean, removeId?: number) => {
    if (refreshAll) {
      refresh(typeLocations);
    } else if (removeId) {
      setLocations(locations.filter((location) => location.id !== removeId));
    }
  };

  return (
    <div className="management-container">
      {messageContent}
      <h1 className="pt-3 pr-3 pb-3">Tariff Management</h1>
      <div>
        {/* Tabs for small screens */}
        <div className="flex border-b mb-4">
          <Button
            type="ride"
            customClassName={`${activeTab === "tariffs" ? "active" : ""}`}
            onClick={() => setActiveTab("tariffs")}
          >
            Tariffs
          </Button>
          <Button
            type="ride"
            customClassName={`${activeTab === "locations" ? "active" : ""}`}
            onClick={() => setActiveTab("locations")}
          >
            Locations
          </Button>
        </div>
        {activeTab === "tariffs" ? (
          <TariffList
            items={tariffs}
            refresh={refreshTariffs}
            fromLocations={locations}
            toLocations={locations}
            setParentMessage={setMessage}
          />
        ) : (
          <LocationList
            items={locations}
            refresh={refreshLocations}
            setParentMessage={setMessage}
          />
        )}
      </div>
    </div>
  );
};

export default App;
