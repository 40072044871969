import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import "../Tariff/Table.css";
import { Vehicle } from "../../Types/Vehicle";

interface VehicleTableProps {
  details: Vehicle[];
  handleSelect: Function;
  setDoUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setDoDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const VehicleTable: React.FC<VehicleTableProps> = (tableData) => {
  return (
    <div className="table">
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>License Plate</th>
            <th>Vehicle Model</th>
            <th>Driver</th>
            <th className="no-border"></th>
            <th className="no-border"></th>
          </tr>
        </thead>
        <tbody>
          {tableData.details.map((props: Vehicle) => {
            const handleSelect = (e: { preventDefault: () => void }) => {
              e.preventDefault();
              tableData.handleSelect(props);
            };
            return (
              <tr key={props.id}>
                <td>{props.licencePlate}</td>
                <td>
                  {props.make} {props.model}
                </td>
                <td>
                  {props.driver?.firstName} {props.driver?.lastName}
                </td>
                <td className="action-button-container">
                  <div className="action-button">
                    <ModeEditIcon
                      onClick={(e) => {
                        tableData.setDoUpdate(true);
                        handleSelect(e);
                      }}
                    ></ModeEditIcon>
                  </div>
                </td>
                <td className="action-button-container">
                  <div className="action-button">
                    <DeleteIcon
                      onClick={(e) => {
                        tableData.setDoDelete(true);
                        handleSelect(e);
                      }}
                    ></DeleteIcon>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VehicleTable;
