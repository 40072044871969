import React, { ChangeEvent, useState } from "react";
import "./LocationList.css";
import { Location } from "../../Types/Location";
import Modal from "@/components/Modal/Modal";
import { AxiosError } from "axios";
import Item from "./Item";
import {
  delWithAuthHeader,
  patchWithAuthHeader,
  postWithAuthHeader,
} from "@/utils/apiUtils";
import Button from "@/components/common/Button";
import { ActionProps } from "../../Types/ActionProps";
import { ButtonType } from "../../Types/ButtonType";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import LocationTable from "./LocationTable";
import LocationListMobile from "./LocationListMobile";
import { isMobile } from "@/utils/globalFuncs";
import NoAvailableDataComponent from "../NoAvailableDataComponent";

const APPEND_URL = "/client/locations";

interface IMyProps {
  items: Location[] | undefined;
  refresh: Function;
  fromLocations?: Location[];
  toLocations?: Location[];
  setParentMessage?: Function;
}

const initLocation = {
  location_name: "",
  airportInd: false,
};

const LocationList: React.FC<IMyProps> = (props: IMyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocation] = useState<Location>(initLocation);
  const [modalMessage, setModalMessage] = useState<Message | undefined>({});
  const [doCreate, setDoCreate] = useState(false);
  const [doUpdate, setDoUpdate] = useState(false);
  const [doDelete, setDoDelete] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    setModalMessage({});
  };

  const closeModal = () => {
    setLocation(initLocation);
    setDoCreate(false);
    setDoDelete(false);
    setDoUpdate(false);
    setIsOpen(false);
  };

  const handleSelect = (location: Location) => {
    setLocation(location);
    openModal();
  };

  const handleDelete = async () => {
    if (location) {
      await delWithAuthHeader(`${APPEND_URL}/${location.id}`)
        .then((response) => {
          if (response.data.data === true) {
            closeModal();
            props.refresh(false, location.id);
            if (props.setParentMessage) {
              props.setParentMessage({
                message: `Location '${location?.location_name}' deleted successfuly`,
                severity: Severity.Info,
              }); // Clear parent message
            }
          }
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const deleteAction: ActionProps = {
    name: "Delete",
    method: handleDelete,
    buttonType: ButtonType.delete,
  };

  const handleUpdate = async () => {
    console.log(location);
    if (location && location.id) {
      await patchWithAuthHeader(`${APPEND_URL}`, location)
        .then((response) => {
          props.refresh(true);
          setModalMessage({
            message: response?.data.message,
            severity: Severity.Info,
          });
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const updateAction: ActionProps = {
    name: "Update",
    method: handleUpdate,
    buttonType: ButtonType.primary,
  };

  const handleCreate = async () => {
    console.log(location);
    if (
      location &&
      location.location_name !== null &&
      location.location_name.length > 0
    ) {
      await postWithAuthHeader(`${APPEND_URL}`, location)
        .then((response) => {
          props.refresh(true);
          setLocation(initLocation);
          setModalMessage({
            message: response?.data.message,
            severity: Severity.Info,
          });
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const createAction: ActionProps = {
    name: "Create",
    method: handleCreate,
    buttonType: ButtonType.primary,
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setLocation({
      ...location,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (name: string, value: boolean) => {
    setLocation({
      ...location,
      [name]: value,
    });
  };

  const actions: ActionProps[] = [];
  if (doCreate) {
    actions.push(createAction);
  }
  if (doUpdate) {
    actions.push(updateAction);
  }
  if (doDelete) {
    actions.push(deleteAction);
  }
  return (
    <div>
      <div className="add-new-button">
        <p>
          <b>{props.items && props.items.length} </b>
          {props?.items?.length === 1 ? "Location" : "Locations"}
        </p>
        <Button
          onClick={() => {
            handleSelect(location);
            setDoCreate(true);
          }}
        >
          Add Location
        </Button>
      </div>
      {props.items && props.items?.length > 0 ? (
        isMobile() ? (
          <LocationListMobile
            details={props.items ?? []}
            handleSelect={handleSelect}
            setDoUpdate={setDoUpdate}
            setDoDelete={setDoDelete}
          ></LocationListMobile>
        ) : (
          <LocationTable
            details={props.items ?? []}
            handleSelect={handleSelect}
            setDoUpdate={setDoUpdate}
            setDoDelete={setDoDelete}
          ></LocationTable>
        )
      ) : (
        <NoAvailableDataComponent />
      )}
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        actions={actions}
        title={"Location Details"}
        message={modalMessage}
      >
        {location && (
          <Item
            item={location}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}
      </Modal>
    </div>
  );
};

export default LocationList;
