import { useEffect, useState } from "react";
import Modal from "@components/Modal/Modal";
import { ClientResponse, Ride } from "@components/ShareARide/Types/Ride";
import { AxiosError } from "axios";
import RideItem from "@components/ShareARide/Ride/RideItem";
import "./ClientBookings.css";
import { useLocation } from "react-router-dom";
import {
  getWithAuthHeader,
  patchWithAuthHeader,
  postWithAuthHeader,
} from "@/utils/apiUtils";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import dayjs from "dayjs";
import ClientBookingDesktop from "./ClientBookingDesktop";
import ClientBookingMobile from "./ClientBookingMobile";
import { isMobile } from "@/utils/globalFuncs";
import { Period } from "@/enums/PeriodEnum";
import { ActionProps } from "@/components/ShareARide/Types/ActionProps";
import { ButtonType } from "@/components/ShareARide/Types/ButtonType";
import { hasAssociatedClient } from "@/utils/authUtils";
import { getMessageContent } from "@/components/Account/accountUtilities";
import ConfirmDecline from "@/components/ShareARide/Ride/ConfirmDecline";

const APPEND_URL = "/client/rides";

const ClientBookings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doAccept, setDoAccept] = useState(false);
  const [doComplete, setDoComplete] = useState(false);
  const [doReject, setDoReject] = useState(false);
  const [showOnlyClientDetails, setShowOnlyClientDetails] = useState(false);
  const [ridesOnView, setRidesOnView] = useState<Ride[]>();
  const [ride, setRide] = useState<Ride>();
  const [clientResponse, setClientResponse] = useState<ClientResponse>();
  const myParam = useLocation().search;
  const [id, setId] = useState<string | null>(
    new URLSearchParams(myParam).get("id")
  );
  const [message, setMessage] = useState<Message>();
  const [modalMessage, setModalMessage] = useState<Message>();
  const [periodOnView, setPeriodOnView] = useState<Period>(Period.today);
  const [clientExists, setClientExists] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const [showConfirmationMessage, setShowConfirmationMessage] =
    useState<boolean>(true);

  useEffect(() => {
    const handleRideSelect = (ride: Ride, period?: Period) => {
      setRide(ride);
      setDoAccept(true);
      setDoComplete(true);
      setDoReject(true);

      openModal();

      setId(null);
    };

    async function fetchClient(period: Period) {
      return await handleSearch(period);
    }

    async function checkClientExists() {
      hasAssociatedClient()
        .then((response) => {
          setClientExists(response);
          if (response === false) {
            setModalMessage({
              message:
                "Please start by creating a client profile. You will then be able to view ride requests.",
              severity: Severity.Warning,
            });
            openModal();
          }
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }

    if (clientExists === false) {
      checkClientExists();
    }

    if (clientExists) {
      if (id) {
        getWithAuthHeader(`${APPEND_URL}/ride/${id}`)
          .then((response) => {
            console.log(response.data.data);
            handleRideSelect(response.data.data);

            const isSameDate = (date1: Date, date2: Date): boolean =>
              date1.toDateString() === date2.toDateString();

            const isYesterday = (date: Date, today: Date): boolean => {
              const yesterday = new Date(today);
              yesterday.setDate(today.getDate() - 1);
              return isSameDate(date, yesterday);
            };

            const isTomorrow = (date: Date, today: Date): boolean => {
              const tomorrow = new Date(today);
              tomorrow.setDate(today.getDate() + 1);
              return isSameDate(date, tomorrow);
            };

            // Main logic
            const currentDate = new Date();
            const rideDate = new Date(response.data.data.date);

            if (isSameDate(rideDate, currentDate)) {
              setPeriodOnView(Period.today);
            } else if (isYesterday(rideDate, currentDate)) {
              setPeriodOnView(Period.yesterday);
            } else if (isTomorrow(rideDate, currentDate)) {
              setPeriodOnView(Period.tomorrow);
            } else if (rideDate < currentDate) {
              setPeriodOnView(Period.past);
            } else {
              setPeriodOnView(Period.future);
            }

            fetchClient(periodOnView);
          })
          .catch((error: unknown) => {
            if (error instanceof AxiosError) {
              // setMessage({ message: error.response?.data.message, severity: Severity.Error });
            }
          });
      } else {
        fetchClient(periodOnView);
      }
    }
  }, [id, periodOnView, clientExists]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setRide(undefined);
    setIsModalOpen(false);
    setModalMessage(undefined);
    setShowConfirmationModal(false);
  };

  async function handleSearch(period: Period) {
    console.log(period);
    const response = await getWithAuthHeader(`${APPEND_URL}/${period}`)
      .then((response) => {
        setRidesOnView(response.data.data);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          setModalMessage({
            message: error.response?.data.message,
            severity: Severity.Error,
          });
        }
      });
    return response;
  }

  const handleComplete = async () => {
    if (ride) {
      await patchWithAuthHeader(`${APPEND_URL}/complete/${ride.id}`, {})
        .then((response) => {
          console.log(response.data.data);
          setRide(response.data.data);
          setModalMessage({
            message: response?.data.message,
            severity: Severity.Info,
          });
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
      handleSearch(periodOnView);
    }
  };

  const completeAction: ActionProps = {
    name: "Complete",
    method: handleComplete,
    buttonType: ButtonType.primary,
    disabled:
      ride?.passengers[0].status === "UNCONFIRMED" ||
      ride?.status === "UNCONFIRMED" ||
      ride?.status === "COMPLETED" ||
      ride?.status === "REJECTED",
  };

  const handleAccept = async () => {
    console.log("ride", ride);
    if (ride) {
      await postWithAuthHeader(`${APPEND_URL}/accept`, clientResponse)
        .then((response) => {
          console.log(response.data.data);
          setRide(response.data.data);
          setModalMessage({
            message: response?.data.message,
            severity: Severity.Info,
          });
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
      handleSearch(periodOnView);
    }
  };

  const acceptAction: ActionProps = {
    name: ride?.status === "ACCEPTED" ? "Update" : "Accept",
    method: handleAccept,
    buttonType: ButtonType.primary,
    disabled:
      ride?.passengers[0].status === "UNCONFIRMED" ||
      ride?.status === "REJECTED" ||
      ride?.status === "COMPLETED",
  };

  const handleReject = async () => {
    if (ride) {
      await patchWithAuthHeader(`${APPEND_URL}/reject/${ride.id}`, {})
        .then((response) => {
          console.log(response.data.data);
          setRide(response.data.data);
          setShowConfirmationMessage(false);
          setModalMessage({
            message: response?.data.message,
            severity: Severity.Info,
          });
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
      handleSearch(periodOnView);
    }
  };

  const rejectAction: ActionProps = {
    name: "Reject",
    method: handleReject,
    buttonType: ButtonType.delete,
    disabled:
      ride?.passengers[0].status === "UNCONFIRMED" ||
      ride?.status === "REJECTED" ||
      ride?.status === "COMPLETED",
  };

  const today = dayjs();
  const todayDay = today.date();
  const yesterday = today.subtract(1, "day").date();
  const tomorrow = today.add(1, "day").date();

  let messageContent;
  console.log(message);
  if (message) {
    messageContent = getMessageContent(message);
  }

  let actions = [];
  if (doComplete && completeAction.disabled === false) {
    actions.push(completeAction);
  }
  if (doAccept && acceptAction.disabled === false) {
    actions.push(acceptAction);
  }
  if (doReject && rejectAction.disabled === false) {
    actions.push(rejectAction);
  }

  const getModalMessage = () => {
    if (modalMessage) {
      return modalMessage;
    } else if (ride?.passengers[0].status === "UNCONFIRMED") {
      return {
        message: `Please wait for the passenger to confirm emain to perform any action.`,
        severity: Severity.Warning,
      };
    }
  };

  return (
    <>
      {messageContent}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        actions={showOnlyClientDetails ? [] : actions}
        title={"Ride Details"}
        message={getModalMessage()}
      >
        {ride && !showConfirmationModal && (
          <RideItem
            ride={ride}
            key={ride.id}
            showOnlyClientDetails={showOnlyClientDetails}
            setClientResponse={setClientResponse}
          />
        )}
        {ride && showConfirmationModal && (
          <ConfirmDecline
            showConfirmationMessage={showConfirmationMessage}
          ></ConfirmDecline>
        )}
      </Modal>
      {isMobile() ? (
        <ClientBookingMobile
          today={todayDay}
          yesterday={yesterday}
          tomorrow={tomorrow}
          rejectAction={rejectAction}
          acceptAction={acceptAction}
          completeAction={completeAction}
          ridesOnView={ridesOnView}
          periodOnView={periodOnView}
          setPeriodOnView={setPeriodOnView}
          setIsModalOpen={setIsModalOpen}
          setRide={setRide}
          setShowOnlyClientDetails={setShowOnlyClientDetails}
          setDoAccept={setDoAccept}
          setDoComplete={setDoComplete}
          setDoReject={setDoReject}
          setShowConfirmationModal={setShowConfirmationModal}
        ></ClientBookingMobile>
      ) : (
        <ClientBookingDesktop
          today={todayDay}
          yesterday={yesterday}
          tomorrow={tomorrow}
          rejectAction={rejectAction}
          acceptAction={acceptAction}
          completeAction={completeAction}
          periodOnView={periodOnView}
          ridesOnView={ridesOnView}
          setPeriodOnView={setPeriodOnView}
          setIsModalOpen={setIsModalOpen}
          setRide={setRide}
          setShowOnlyClientDetails={setShowOnlyClientDetails}
          setDoAccept={setDoAccept}
          setDoComplete={setDoComplete}
          setDoReject={setDoReject}
          setShowConfirmationModal={setShowConfirmationModal}
        ></ClientBookingDesktop>
      )}
    </>
  );
};

export default ClientBookings;
