import React from "react";
import "./Contact.css";
import { Client } from "../../Types/Client";
import {
  FaCreditCard,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaLocationArrow,
  FaAddressCard,
  FaPaypal,
  FaCashRegister,
  FaWhatsapp,
  FaViber,
} from "react-icons/fa";

interface IMyProps {
  client: Client;
  showBookingSystem: boolean;
}

const Contact: React.FC<IMyProps> = (props: IMyProps) => {
  let clientContext = (
    <div className="contact-card-header">
      <h1>
        <FaAddressCard className="icon" /> <b>{props.client.companyName}</b>
      </h1>
      <p>Head Driver</p>
    </div>
  );

  let clientImage = null;
  try {
    let module = require(`@common/${props.client.uniqueIdentifier}/${props.client.uniqueIdentifier}.png`);
    clientImage = (
      <div style={{ textAlign: "center" }}>
        <img
          src={module}
          style={{ margin: "5px", borderRadius: "15px" }}
          width="312"
          height="60"
          alt={props.client.uniqueIdentifier}
        />
      </div>
    );
  } catch (error) {
    console.warn("Module not found:", error);
  }

  const formatPhoneNumberForLink = (phone: string | undefined) => {
    if (!phone) return null;
    return phone.replace(/\s+/g, "").replace(/-/g, "");
  };

  let phone = props.client.phone ? props.client.phone : "+30 694 771 1601";
  const formattedPhone = formatPhoneNumberForLink(phone);

  return (
    <div>
      {clientImage}
      {clientContext}
      {!props.showBookingSystem && (
        <div className="contact-card">
          <div className="contact-item">
            <FaUser className="icon" />
            <span>
              {props.client.firstName} {props.client.lastName}
            </span>
          </div>
          <div className="contact-item">
            <FaEnvelope className="icon" />
            {props.client.email ? (
              <a href={`mailto:${props.client.email}`} className="contact-link">
                {props.client.email}
              </a>
            ) : (
              <span>Email not provided</span>
            )}
          </div>
          <div className="contact-item">
            <FaLocationArrow className="icon" />
            <span>
              {props.client.city !== null && props.client.city !== undefined
                ? `${props.client.city}, `
                : ""}
              {props.client.country}
            </span>
          </div>
          <div className="contact-item">
            <FaPhone className="icon" />
            {phone ? (
              <span>
                <a
                  href={`tel:${formattedPhone}`}
                  className="contact-link phone-link"
                  title="Call this number"
                >
                  {phone}
                </a>
                {" or "}
                <a
                  href={`https://wa.me/${formattedPhone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-link whatsapp-link icon-large"
                  title="Message on WhatsApp"
                >
                  <FaWhatsapp />
                </a>
                <a
                  href={`viber://chat?number=${formattedPhone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-link viber-link"
                  title="Message on Viber"
                >
                  <FaViber />
                </a>
              </span>
            ) : (
              <span>Phone not provided</span>
            )}
          </div>
          <div className="contact-item payment-methods">
            <p>Payment methods</p>
            <div className="payment-icons">
              <FaCreditCard className="icon" />
              <FaPaypal className="icon" />
              <FaCashRegister className="icon" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
