import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

interface DatePickerProps {
  handleCalendarChange: (date: string) => void;
}

const BasicDatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          defaultValue={dayjs().add(1, "day")}
          format="DD/MM/YYYY"
          onChange={(date) => {
            if (date) {
              const d = date;
              props.handleCalendarChange(d.format("YYYY-MM-DD"));
            }
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default BasicDatePicker;
