export const formattedTimestamp = (timestamp: number | string) => {
  if (typeof timestamp == "number" && !Number.isNaN(timestamp)) {
    return new Date(timestamp).toISOString();
  }
  return timestamp;
};

export const formattedTime = (hour?: number, minute?: number) => {
  return (
    (hour ? hour.toString().padStart(2, "0") : "00") +
    ":" +
    (minute ? minute.toString().padStart(2, "0") : "00")
  );
};

export const renderNumberOptions = (
  max: number,
  step?: number,
  startFrom?: number
) => {
  const options: { value: number; label: string }[] = [];
  step = step ? step : 1;
  startFrom = startFrom ? startFrom : 0;
  for (let i = startFrom; i <= max; i = i + step) {
    options.push({ value: i, label: i.toString() });
  }
  return options;
};
