import React, { ChangeEventHandler, useEffect } from 'react';
import './BusinessCardOrderItem.css';
import { BusinessCardOrder, OrderStatus } from '../../Types/BusinessCardOrder';
import SelectEnum, { getKeyFromValue } from '@/components/common/Select/SelectEnum';
import {CustomerPaymentStatusOptions} from '../../Types/Status';
import { isSuperuser } from '@/utils/authUtils';

interface IMyProps {
  item: BusinessCardOrder,
  handleInputChange: ChangeEventHandler,
  handleQuantityChange: ChangeEventHandler,
  handleSelectChange: Function
}

const BusinessCardOrderItem: React.FC<IMyProps> = (props:IMyProps) => {

  useEffect(() => {
      // eslint-disable-next-line
  }, []);


const handleSelectChange = (
  enumObject: Record<string, string>,
  name: string
) => (selectedOption: any) => {
  props.handleSelectChange(name, getKeyFromValue(enumObject, selectedOption));
};

  return (
  <div>
    <div className="div-container">
      <div className="div-content">

      {props.item.cancelled && <label className="type-UNCONFIRMED">CANCELLED</label>}
      <label className="bookatrip">Client: {props.item.client?.id} / {props.item.client?.companyName} / {props.item.client?.firstName} {props.item.client?.lastName}</label>
      <div><p>Order Id: <b>{props.item.id}</b> / <b>{props.item.shippingAddressLine1}, {props.item.shippingPostcode}</b></p></div>

      <div>
        <label className="bookatrip">Quantity</label>
        <input type="number" name="quantity" value={props.item.quantity} onChange={props.handleQuantityChange} min={40} disabled={props.item.cancelled}/>
        <label className="bookatrip">Price  (+2€ delivery fee)</label>
        <input type="number" name="priceEuro" value={props.item.priceEuro} disabled/> <span>€</span>
        <label className="bookatrip">Shipping Address</label>
        <input type="text" name="shippingAddressLine1" value={props.item.shippingAddressLine1} onChange={props.handleInputChange} placeholder='Address Line 1' required disabled={props.item.cancelled}/>
        <input type="text" name="shippingAddressLine2" value={props.item.shippingAddressLine2} onChange={props.handleInputChange} placeholder='Address Line 2' disabled={props.item.cancelled}/>
        <input type="text" name="shippingCity" value={props.item.shippingCity} onChange={props.handleInputChange} placeholder='Town/City' disabled={props.item.cancelled}/>
        <input type="text" name="shippingPostcode" value={props.item.shippingPostcode} onChange={props.handleInputChange} placeholder='Postcode' disabled={props.item.cancelled}/>
        <label className="bookatrip">Payment Status</label>
        <SelectEnum selectedValue={props.item.paymentStatus} enumObject={CustomerPaymentStatusOptions} onSelect={handleSelectChange(CustomerPaymentStatusOptions, "paymentStatus")} viewOnly={props.item.cancelled}/>
        <p className='description'>*We will share payment details via email once you make the order.</p>
        <label className="bookatrip">Order Status</label>
        <SelectEnum selectedValue={props.item.orderStatus} enumObject={OrderStatus} onSelect={handleSelectChange(OrderStatus, "orderStatus")} viewOnly={isSuperuser() ? false : true}/>
        <label className="bookatrip">Tracking Number</label>
        <input type="text" name="trackingNumber" value={props.item.trackingNumber} onChange={props.handleInputChange} placeholder='e.g. GB0123456789' disabled={isSuperuser() ? false : true}/>
        <p className='description'>*Tracking will be available once the order is sent.</p>
      </div>

      </div>
    </div>
  </div>
)};

export default BusinessCardOrderItem;