// App.tsx
import React, { useState, useEffect, ChangeEvent } from "react";
import { AxiosError } from "axios";
import { getMessageContent } from "@components/Account/accountUtilities";
import { Message } from "@components/Account/types/Message";
import Severity from "@components/common/types/Severity";
import { useLocation } from "react-router-dom";
import { Client } from "../Types/Client";
import { RideResponse } from "../Types/Ride";
import Button from "@common/Button";
import "./BookATrip.css";
import SelectValue from "@common/Select/SelectValue";
import Modal from "@/components/Modal/Modal";
import { renderNumberOptions } from "@/utils/formatUtils";
import Contact from "../Client/Contact/Contact";
import ClientList, { ClientAbstract } from "./ClientList";
import { get, post } from "@/utils/apiUtils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import BasicDatePicker from "@/components/common/datepicker/Datepicker";
import BasicTimePicker from "@/components/common/timepicker/TimePicker";
import { Location } from "../Types/Location";

interface RideLocal {
  id?: number;
  destinationFrom: number;
  destinationFromAddress?: string
  destinationTo: number;
  destinationToAddress?: string;
  date: string;
  hour: string;
  minute: string;
  flightNo: string;
  passenger: PassengerLocal;
  clientId?: number;
}

interface PassengerLocal {
  id?: number;
  firstName?: string;
  lastName?: string;
  people?: number;
  luggage?: number;
  email?: string;
  rideId?: number;
  notes?: string;
}

interface TariffLocal {
  id: number;
  fromLocation: Location;
  toLocation: Location;
  priceEuro: number;
  client?: Client;
}

const App: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showBookingSystem, setShowBookingSystem] = useState(false); // New state
  const [locations, setLocations] = useState<Location[]>([]);
  const [location, setLocation] = useState<Location>();
  const [destination, setDestination] = useState<Location>();
  const [tariffs, setTariffs] = useState<TariffLocal[]>([]);
  const [openRides, setOpenRides] = useState<RideResponse[]>([]);
  const myParam = useLocation().search;
  const [clientName] = useState<string | null>(
    new URLSearchParams(myParam).get("client")
  );
  const [client, setClient] = useState<Client | null>(null);
  const [clients, setClients] = useState<ClientAbstract[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState<boolean>(true);
  const [clientId, setClientId] = useState<number | null>(null);
  const [newRide, setNewRide] = useState<RideLocal>({
    destinationFrom: 0,
    destinationTo: 0,
    date:
      new Date().getHours() < 23
        ? new Date(new Date().setDate(new Date().getDate() + 1))
            .toISOString()
            .split("T")[0]
        : new Date(new Date().setDate(new Date().getDate() + 2))
            .toISOString()
            .split("T")[0],
    hour:
      new Date().getHours() < 23
        ? (new Date().getHours() + 1).toString().padStart(2, "0")
        : new Date().getHours().toString().padStart(2, "0"),
    minute: "00",
    flightNo: "",
    passenger: {},
  });
  const [passenger, setPassenger] = useState<PassengerLocal>({
    firstName: "",
    lastName: "",
    people: 1,
    luggage: 0,
    email: "",
  });
  const [message, setMessage] = useState<Message | null>(null);
  const [resetDestination, setResetDestination] = useState<boolean>(false);
  const SHOW_BOOKING_SYSTEM =
    process.env.REACT_APP_CLIENT_BOOKING_SYSTEM_ENABLED === "true"
      ? true
      : false;
  const [filter, setFilter] = useState<string>("");

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value.toLowerCase());
  };
  const toggleBookingSystem = () => setShowBookingSystem(!showBookingSystem);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const setMessageAndOpenModal = (
    message: string,
    severity: Severity,
    code?: string
  ) => {
    setMessage({ message, severity, code });
    openModal();
  };

  const clearMessageAndCloseModal = () => {
    setMessage(null);
    closeModal();
  };

  useEffect(() => {
    async function fetchClient() {
      if (clientName) {
        // Fetch locations
        const response = await get(`/clients/${clientName}`)
          .then((response) => {
            setClient(response.data.data);
            var clientId = response.data.data.id;
            setClientId(clientId);
            setNewRide({ ...newRide, clientId: clientId });
          })
          .catch((error: unknown) => {
            if (error instanceof AxiosError) {
              setMessageAndOpenModal(
                error.response?.data.message,
                Severity.Error
              );
            }
          });
        return response;
      }
    }

    async function fetchClients() {
      setIsLoadingClients(true);
      if (clients.length === 0) {
        get(`/clients/all`)
          .then((response) => {
            console.log(response.data.data);
            setClients(response.data.data);
            setIsLoadingClients(false);
          })
          .catch((error: unknown) => {
            if (error instanceof AxiosError) {
              setIsLoadingClients(false);
              setMessageAndOpenModal(
                error.response?.data.message,
                Severity.Error
              );
            }
          });
      }
    }

    fetchClient();
    fetchClients();

    var clientLocations = "";
    if (clientId) {
      clearMessageAndCloseModal();
      clientLocations = "/client/" + clientId;

      // Fetch locations
      get(`/locations` + clientLocations)
        .then((response) => {
          setLocations(response.data.data);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessageAndOpenModal(
              error.response?.data.message,
              Severity.Error
            );
          }
        });
    } else {
      if (!isLoadingClients)
        setMessageAndOpenModal("Client does not exist", Severity.Error);
    }
    // eslint-disable-next-line
  }, [clientName, clientId]);

  var clientPath = "";
  if (clientId) {
    clientPath = "/" + clientId;
  }

  const handleLocationOptionChange = (value: number, property: string) => {
    const locationId = value;

    if (location?.id !== locationId) {
      setResetDestination(true);
    }

    try {
      get(`/tariffs` + clientPath + `?locationId=${locationId}`)
        .then((response) => {
          setTariffs(response.data.data);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessageAndOpenModal(
              error.response?.data.message,
              Severity.Error
            );
          }
        });
      setLocation(
        locations.filter((location) => location.id === Number(locationId))[0]
      );
      setNewRide({
        ...newRide,
        [property]: value,
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setMessageAndOpenModal(error.response?.data.message, Severity.Error);
      }
    }
  };

  const handleDestinationChange = (e: number) => {
    setResetDestination(false);

    var tariff = tariffs.filter((tariff) => tariff.id === Number(e))[0];

    setDestination(tariff.toLocation);
    if (tariff.toLocation.id)
      setNewRide({
        ...newRide,
        destinationTo: tariff.toLocation.id,
      });
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setNewRide({
      ...newRide,
      [e.target.name]: e.target.value,
    });
  };

  const handleCalendarChange = (date: string) => {
    setNewRide({
      ...newRide,
      date: date,
    });
  };

  const handleTimepickerChange = (hour: string, minute: string) => {
    setNewRide({
      ...newRide,
      hour: hour,
      minute: minute,
    });
  };

  const validateData = () => {
    if (!newRide.date || newRide.date === "") {
      setMessageAndOpenModal("Please enter a date", Severity.Error);
      return false;
    }
    if (!newRide.hour || !newRide.minute) {
      setMessageAndOpenModal("Please enter a time", Severity.Error);
      return false;
    }
    if (!passenger.firstName) {
      setMessageAndOpenModal("Please enter passenger name", Severity.Error);
      return false;
    }
    if (!passenger.email) {
      setMessageAndOpenModal("Please enter passenger email", Severity.Error);
      return false;
    }
    if (
      passenger.people &&
      passenger.people < 1 &&
      passenger.luggage &&
      passenger.luggage < 1
    ) {
      setMessageAndOpenModal(
        "Please enter valid passenger and luggage numbers. If this is a parcel delivery, please add at least one luggage.",
        Severity.Error
      );
      return false;
    }

    return true;
  };

  const handleSubmitNew = () => {
    setIsLoadingClients(false);
    if (validateData() === true) {
      // Concatenate date, hour, and minute to form a datetime string
      try {
        // Update Axios call to include the base URL
        post(`/rides`, { ...newRide, passenger })
          .then((response) => {
            setOpenRides([...openRides, response.data.data]);
            // Handle success as needed
            setMessageAndOpenModal(
              `Hurray! You have created a new ride. Confirm email and you will be hearing from ${
                client?.companyName ? client.companyName : clientName
              } soon!`,
              Severity.Info
            );
            toggleBookingSystem();
          })
          .catch((error: unknown) => {
            if (error instanceof AxiosError) {
              setMessageAndOpenModal(
                error.response?.data.message,
                Severity.Error,
                error.response?.data.code
              );
            }
          });
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          setMessageAndOpenModal(error.response?.data.message, Severity.Error);
        }
      }
    }
  };

  const handlePassengerInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setPassenger({
      ...passenger,
      [e.target.name]: e.target.value,
    });
  };

  const handlePassengerSelectChange = (e: number, label: string) => {
    setPassenger({
      ...passenger,
      [label]: e,
    });
  };

  var showFlightNo =
    (location != null && location.airportInd === true) ||
    (destination != null && destination.airportInd === true);

  let messageContent;
  if (message !== null) {
    console.log(message);
    messageContent = getMessageContent(message);
  }

  if (client === null) {
    let modal = null;
    if (clientName && !isLoadingClients) {
      modal = (
        <Modal isOpen={isOpen} onClose={closeModal}>
          {messageContent}
        </Modal>
      );
    }

    return (
      <div className="div-container  book-a-trip  ">
        {!isLoadingClients ? (
          modal
        ) : (
          <>
            <div className="spinner-container">
              <CircularProgress />
            </div>
          </>
        )}
        <div className="search-clients">
          <p className="search-label">
            Search a client by city, country, client name or unique identifier
          </p>
          <div className="search-input-container">
            <SearchIcon></SearchIcon>
            <input
              type="text"
              placeholder="Type here..."
              value={filter}
              onChange={handleFilterChange}
              className="filter-input"
            />
          </div>
        </div>
        <div className="client-list">
          <ClientList
            clients={clients}
            isLoadingClients={isLoadingClients}
            filter={filter}
            handleFilterChange={handleFilterChange}
          />
        </div>
      </div>
    );
  }

  var locationOptions = locations?.map((location) => ({
    value: location.id,
    label:
      location.location_name + `${location.airportInd ? " (Airport)" : ""}`,
  }));

  var tariffOptions = tariffs.map((tariff) => ({
    value: tariff.id,
    label:
      tariff.toLocation.location_name +
      `${tariff.toLocation.airportInd ? " (Airport)" : ""} - from ` +
      tariff.priceEuro +
      "€",
  }));

  return (
    <div className="div-container client-card-container">
      <div className="opacity-div"></div>
      {!isLoadingClients && (
        <Modal isOpen={isOpen} onClose={closeModal}>
          {messageContent}
        </Modal>
      )}

      <div
        className={`div-content client-card-big ${
          SHOW_BOOKING_SYSTEM ? "booking-system" : ""
        }`}
      >
        <Contact client={client} showBookingSystem={showBookingSystem} />

        <Button
          onClick={toggleBookingSystem}
          customClassName="smooth-transition-button show-button"
        >
          {showBookingSystem ? (
            <span>
              {SHOW_BOOKING_SYSTEM
                ? "Hide Booking System"
                : "Not quite there yet... but very soon!:)"}
              <ArrowDropUpIcon></ArrowDropUpIcon>
            </span>
          ) : (
            <span>
              {SHOW_BOOKING_SYSTEM
                ? "Show Booking System"
                : "Show Booking System (coming soon)"}
              <ArrowDropDownIcon></ArrowDropDownIcon>
            </span>
          )}
        </Button>
        {SHOW_BOOKING_SYSTEM === false && showBookingSystem && (
          <div>
            <p>Dear Customer,</p>
            <p>You will soon be able to book online.</p>
            <p>
              Until then, please contact us directly using the details from the
              card above.
            </p>
            <p>Thank you and looking forward to... Transfero you!</p>
            <p>{client ? client.companyName : "The Transfero Team"}</p>
          </div>
        )}
        {SHOW_BOOKING_SYSTEM && showBookingSystem && (
          <div className="booking-system">
            <div>
              <label className="bookatrip">
                <b>PICK-UP PRESET</b>
              </label>
              <SelectValue
                options={locationOptions}
                onSelectChange={(e) =>
                  handleLocationOptionChange(e, "destinationFrom")
                }
              />
            </div>
            <div>
                <label className="description">Pick-up address</label>
                <input
                  type="text"
                  name="destinationFromAddress"
                  placeholder="123 Example Street, City, Postcode"
                  onChange={handleInputChange}
                />
            </div>
            <div>
              <label className="bookatrip">
                <b>DROP-OFF PRESET</b><br/>
              </label>
              
              <SelectValue
                noOptionsMessage={() => "Select a pick-up preset option first"}
                options={tariffOptions}
                onSelectChange={(e) => handleDestinationChange(e)}
                resetValue={resetDestination}
              />
            </div>
            <div>
                <label className="description">Drop-off address</label>
                <input
                  type="text"
                  name="destinationToAddress"
                  placeholder="123 Example Street, City, Postcode"
                  onChange={handleInputChange}
                />
            </div>
            {showFlightNo && (
              <div>
                <label className="bookatrip">Flight Number</label>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  name="flightNo"
                  placeholder="No spaces e.g.A3600"
                  onChange={handleInputChange}
                />
              </div>
            )}
            <div className="date-input-container">
              <label className="bookatrip">Date</label>
              <BasicDatePicker handleCalendarChange={handleCalendarChange} />
            </div>
            <div className="date-input-container">
              <label className="bookatrip">Time</label>
              <BasicTimePicker
                handleTimepickerChange={handleTimepickerChange}
                initHour={newRide.hour}
                initMinute={newRide.minute}
              ></BasicTimePicker>
            </div>
            <div className="passenger-info">
              <h4>Passenger Info</h4>
              <div>
                <label>
                  First Name:<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  onChange={handlePassengerInputChange}
                  required
                />
              </div>
              <div>
                <label>Last Name:</label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={handlePassengerInputChange}
                />
              </div>
              <div>
                <label>
                  Email:<span className="required">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handlePassengerInputChange}
                  required
                />
              </div>
              <div>
                <label>People:</label>
                <SelectValue
                  initOption={
                    passenger.people
                      ? {
                          value: passenger.people,
                          label: passenger.people?.toString(),
                        }
                      : undefined
                  }
                  options={renderNumberOptions(50).map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  onSelectChange={(e) =>
                    handlePassengerSelectChange(e, "people")
                  }
                />
              </div>
              <div>
                <label>Luggage:</label>
                <SelectValue
                  initOption={
                    passenger.luggage || passenger.luggage === 0
                      ? {
                          value: passenger.luggage,
                          label: passenger.luggage?.toString(),
                        }
                      : undefined
                  }
                  options={renderNumberOptions(50).map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  onSelectChange={(e) =>
                    handlePassengerSelectChange(e, "luggage")
                  }
                />
              </div>

              <div>
                <label>Notes:</label>
                <textarea
                  className="textarea-width"
                  name="notes"
                  placeholder="Quiet Ride, Baby Seat or any other special requests"
                  onChange={handlePassengerInputChange}
                />
              </div>
            </div>
            <Button
              style={{ width: "100%", marginTop: "15px" }}
              onClick={handleSubmitNew}
            >
              REQUEST A RIDE
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
