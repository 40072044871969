interface ConfirmDeclineProps {
  showConfirmationMessage: boolean;
}

const ConfirmDecline: React.FC<ConfirmDeclineProps> = (
  props: ConfirmDeclineProps
) => {
  return (
    <div>
      <div className="div-container">
        {props.showConfirmationMessage && (
          <div className="div-content">
            <p> Are you sure you want to reject this ride?</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmDecline;
