// App.tsx
import { getMessageContent } from "@/components/Account/accountUtilities";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { Driver, Vehicle } from "../Types/Vehicle";
import VehicleList from "./Vehicle/VehicleList";
import DriverList from "./Driver/DriverList";
import { getWithAuthHeader } from "@/utils/apiUtils";
import Button from "@/components/common/Button";

const APPEND_URL = "/client/fleet";

const typeVehicles = "vehicles";
const typeDrivers = "drivers";

const App: React.FC = () => {
  const [message, setMessage] = useState<Message | null>(null);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [activeTab, setActiveTab] = useState<"vehicles" | "drivers">(
    "vehicles"
  );

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  }

  const refresh = (type: string) => {
    getWithAuthHeader(`${APPEND_URL}/${type}`, false)
      .then((response) => {
        console.log(response.data.data);
        if (type === typeVehicles) {
          setVehicles(response.data.data);
        } else if (type === typeDrivers) {
          setDrivers(response.data.data);
        }
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          setMessage({
            message: error.response?.data.message,
            severity: Severity.Error,
          });
        }
      });
  };

  useEffect(() => {
    refresh(typeVehicles);
    refresh(typeDrivers);
    // eslint-disable-next-line
  }, []);

  const refreshDrivers = (refreshAll: boolean, removeId?: number) => {
    if (refreshAll) {
      refresh(typeDrivers);
    } else if (removeId) {
      setDrivers(drivers.filter((driver) => driver.id !== removeId));
    }
  };

  const refreshVehicles = (refreshAll: boolean, removeId?: number) => {
    if (refreshAll) {
      refresh(typeVehicles);
    } else if (removeId) {
      setVehicles(vehicles.filter((vehicle) => vehicle.id !== removeId));
    }
  };

  return (
    <div className="management-container">
      {messageContent}
      <h1 className="pt-3 pr-3 pb-3">Fleet Management</h1>
      <div className="flex border-b mb-4">
        <Button
          type="ride"
          customClassName={`${activeTab === "vehicles" ? "active" : ""}`}
          onClick={() => setActiveTab("vehicles")}
        >
          Vehicles
        </Button>
        <Button
          type="ride"
          customClassName={`${activeTab === "drivers" ? "active" : ""}`}
          onClick={() => setActiveTab("drivers")}
        >
          Drivers
        </Button>
      </div>
      {activeTab === "vehicles" ? (
        <div>
          <VehicleList
            items={vehicles}
            refresh={refreshVehicles}
            drivers={drivers}
            setParentMessage={setMessage}
          />
        </div>
      ) : (
        <div>
          <DriverList 
            items={drivers} 
            refresh={refreshDrivers}
            setParentMessage={setMessage}
            />
        </div>
      )}
    </div>
  );
};

export default App;
