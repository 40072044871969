import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import "../Tariff/Table.css";
import { Location } from "../../Types/Location";

interface LocationTableProps {
  details: Location[];
  handleSelect: Function;
  setDoUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setDoDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationTable: React.FC<LocationTableProps> = (tableData) => {
  return (
    <div className="table">
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th></th>
            <th>Location</th>
            <th>Airport</th>
            <th className="no-border"></th>
            <th className="no-border"></th>
          </tr>
        </thead>
        <tbody>
          {tableData.details.map((props: Location) => {
            const handleSelect = (e: { preventDefault: () => void }) => {
              e.preventDefault();
              tableData.handleSelect(props);
            };
            return (
              <tr key={props.id}>
                <td>{props.id}</td>
                <td>{props.location_name}</td>
                <td>{props.airportInd ? "Yes" : "No"}</td>
                <td className="action-button-container">
                  <div className="action-button">
                    <ModeEditIcon
                      onClick={(e) => {
                        tableData.setDoUpdate(true);
                        handleSelect(e);
                      }}
                    ></ModeEditIcon>
                  </div>
                </td>
                <td className="action-button-container">
                  <div className="action-button">
                    <DeleteIcon
                      onClick={(e) => {
                        tableData.setDoDelete(true);
                        handleSelect(e);
                      }}
                    ></DeleteIcon>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LocationTable;
