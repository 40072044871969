import { getAuthHeader } from './authUtils';
import axiosInstance from './axiosUtils';

const headers = () => getAuthHeader();

export const get = (url: string, isDeleted?: boolean, headers?: object, urlParams?: string[][]) => 
    {
        console.log(isDeleted);
        if (isDeleted !== undefined) {
            console.log(urlParams);
            if (urlParams) {
                urlParams.push(['isDeleted', isDeleted? 'true' : 'false']);
            } else {
                urlParams = [['isDeleted', isDeleted ? 'true' : 'false']];
            }
        }
        if (urlParams) {
            url = `${url}?${new URLSearchParams(urlParams).toString()}`;
        }

        console.log(url);
        return axiosInstance.get(url, { headers })}
;
export const post = (url: string, data: object | undefined, headers?: object) => axiosInstance.post(url, data, { headers });
export const put = (url: string, data: object | undefined, headers?: object) => axiosInstance.put(url, data, { headers });
export const patch = (url: string, data: object | undefined, headers?: object) => axiosInstance.patch(url, data, { headers });
export const del = (url: string, headers?: object) => axiosInstance.delete(url, { headers });

export const getWithAuthHeader = (url: string, isDeleted?: boolean, urlParams?: string[][]) => get(url, isDeleted, headers(), urlParams);
export const postWithAuthHeader = (url: string, data: object | undefined) => post(url, data, headers());
export const putWithAuthHeader = (url: string, data: object | undefined) => put(url, data, headers());
export const patchWithAuthHeader = (url: string, data: object | undefined) => patch(url, data, headers());
export const delWithAuthHeader = (url: string) => del(url, headers());