import { Tariff } from "../../Types/Tariff";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Table.css";

interface TariffTableProps {
  details: Tariff[];
  handleSelect: Function;
  setDoUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setDoDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const TariffTable: React.FC<TariffTableProps> = (tableData) => {
  return (
    <div className="table">
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Price</th>
            <th>Night Price</th>
            <th className="no-border"></th>
            <th className="no-border"></th>
          </tr>
        </thead>
        <tbody>
          {tableData.details.map((props: Tariff) => {
            const handleSelect = (e: { preventDefault: () => void }) => {
              e.preventDefault();
              tableData.handleSelect(props);
            };
            return (
              <tr key={props.id}>
                <td>{props.fromLocation?.location_name}</td>
                <td>{props.toLocation?.location_name}</td>
                <td>{props.priceEuro} €</td>
                <td>{props.nightPriceEuro} €</td>
                <td className="action-button-container">
                  <div className="action-button">
                    <ModeEditIcon
                      onClick={(e) => {
                        tableData.setDoUpdate(true);
                        handleSelect(e);
                      }}
                    ></ModeEditIcon>
                  </div>
                </td>
                <td className="action-button-container">
                  <div className="action-button">
                    <DeleteIcon
                      onClick={(e) => {
                        tableData.setDoDelete(true);
                        handleSelect(e);
                      }}
                    ></DeleteIcon>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TariffTable;
