import React, { ChangeEventHandler, useEffect } from "react";
import "./Item.css";
import { Location } from "../../Types/Location";
import "../../../../styles/global.css";

interface IMyProps {
  item: Location;
  handleInputChange: ChangeEventHandler;
  handleCheckboxChange: Function;
}

const Item: React.FC<IMyProps> = (props: IMyProps) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleCheckboxChange = (e: any) => {
    const { name, value } = e.target;
    console.log(value);
    const ind = !props.item.airportInd;
    props.handleCheckboxChange(name, ind);
  };

  return (
    <div>
      <div className="div-container">
        <div className="div-content">
          <div>
            <p>
              Location Id: <b>{props.item.id}</b>
            </p>
          </div>
          <label className="tariff">Location Name</label>
          <br />
          <input
            type="text"
            name="location_name"
            value={props.item.location_name}
            onChange={props.handleInputChange}
          />
          <br />
          <br />
          <div className="checkbox-switch tariff">
            Airport
            <label className="switch">
              <input
                type="checkbox"
                name="airportInd"
                checked={props.item.airportInd}
                onChange={handleCheckboxChange}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
