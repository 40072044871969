import React, { useState, useEffect } from "react";
import {
  getWithAuthHeader,
  patchWithAuthHeader,
  postWithAuthHeader,
} from "@utils/apiUtils";
import { AxiosError } from "axios";
import { Client } from "../Types/Client";
import Button from "@/components/common/Button";
import { Message } from "@/components/Account/types/Message";
import { getMessageContent } from "@/components/Account/accountUtilities";
import Severity from "@/components/common/types/Severity";
import "./ClientManagement.css";
import "../../../styles/global.css";
import { isMobile } from "@/utils/globalFuncs";

const APPEND_URL = "/client"; // Base URL for the client API

const initClient: Client = {
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  phone: "",
  status: "UNCONFIRMED",
  active: true,
  addressLine1: "",
  addressLine2: null,
  postcode: "",
  city: "",
  country: "",
  uniqueIdentifier: "",
};

const ClientManagement: React.FC = () => {
  const [client, setClient] = useState<Client>(initClient);
  const [loading, setLoading] = useState<boolean>(false);
  const [isExistingClient, setIsExistingClient] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  }

  useEffect(() => {
    setLoading(true);
    getWithAuthHeader(`${APPEND_URL}`) // Fetch existing client if available
      .then((response) => {
        if (response.data.data) {
          setClient(response.data.data);
          setIsExistingClient(true);
        }
        setLoading(false);
      })
      .catch((error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError && error.response?.status === 404) {
          setIsExistingClient(false);
        } else if (error instanceof AxiosError) {
          setMessage({
            message: error.response?.data.message,
            severity: Severity.Error,
          });
        }
      });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    let postOrPatch = postWithAuthHeader;
    if (isExistingClient) {
      postOrPatch = patchWithAuthHeader;
    }
    if (
      client.firstName &&
      client.lastName &&
      client.email &&
      client.uniqueIdentifier
    ) {
      await postOrPatch(APPEND_URL, client)
        .then((response) => {
          setMessage({
            message: response.data.message,
            severity: Severity.Info,
          });
          setIsExistingClient(true);
          setClient(response.data.data);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    } else {
      setMessage({
        message: "Please fill in all required fields.",
        severity: Severity.Error,
      });
    }
    setLoading(false);
  };

  if (loading) {
    return <div>Loading client details...</div>;
  }

  const SubmitButton = () => {
    return (
      <div className="submit-button-container">
        <Button
          type="submit"
          customClassName="submit-button"
          form="create-update-client-form"
        >
          {isExistingClient ? "Update Client" : "Create Client"}
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className="client-management">
        {messageContent}
        <h1>Client Info</h1>
        <p className="subtitle">
          Here you can create and manage the client information that will appear
          when customers reach your personal booking page.
        </p>
        <form
          onSubmit={handleSave}
          className="client-form"
          id="create-update-client-form"
        >
          <div className="form-groups-container">
            <div className="form-group active-checkbox">
              <div className="checkbox-switch">
                Active:
                <label className="switch">
                  <input
                    type="checkbox"
                    name="active"
                    checked={client.active}
                    onChange={(e) =>
                      setClient((prevClient) => ({
                        ...prevClient,
                        active: e.target.checked,
                      }))
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <p className="description">
                *Deactivating means no new ride requests. Done automatically on
                unpaid subscriptions.
              </p>
            </div>
            <div className="form-group">
              <label>
                First Name<span className="required">*</span>:
                <input
                  type="text"
                  name="firstName"
                  value={client.firstName}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Last Name<span className="required">*</span>:
                <input
                  type="text"
                  name="lastName"
                  value={client.lastName}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Company Name<span className="required">*</span>:
                <input
                  type="text"
                  name="companyName"
                  value={client.companyName}
                  onChange={handleChange}
                  required
                />
              </label>
              <p className="description">
                *Use your name if it’s personal page.
              </p>
            </div>
            <div className="form-group">
              <label>
                Email<span className="required">*</span>:
                <input
                  type="email"
                  name="email"
                  value={client.email}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Address Line 1<span className="required">*</span>:
                <input
                  type="text"
                  name="addressLine1"
                  value={client.addressLine1}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Address Line 2:
                <input
                  type="text"
                  name="addressLine2"
                  value={client.addressLine2 || ""}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Phone<span className="required">*</span>:
                <input
                  type="phone"
                  name="phone"
                  value={client.phone}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Postcode:
                <input
                  type="text"
                  name="postcode"
                  value={client.postcode}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                City:
                <input
                  type="text"
                  name="city"
                  value={client.city}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Country<span className="required">*</span>:
                <input
                  type="text"
                  name="country"
                  value={client.country}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Unique Identifier<span className="required">*</span>:
                <input
                  type="text"
                  name="uniqueIdentifier"
                  value={client.uniqueIdentifier}
                  onChange={handleChange}
                  required
                />
              </label>
              <p className="description">
                *This is used to create a unique link that leads customers to
                your personal booking page.
              </p>
            </div>
          </div>
          {!isMobile() && <SubmitButton></SubmitButton>}
        </form>
      </div>
      {isMobile() && <SubmitButton></SubmitButton>}
    </>
  );
};

export default ClientManagement;
