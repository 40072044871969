// App.tsx
import { getMessageContent } from "@/components/Account/accountUtilities";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { BusinessCardOrder } from "../Types/BusinessCardOrder";
import { getWithAuthHeader } from "@/utils/apiUtils";
import BusinessCardOrderList from "./BusinessCardOrder/BusinessCardOrderList";
import { isSuperuser } from "@/utils/authUtils";
import "./BusinessCardOrders.css";
import { isMobile } from "@/utils/globalFuncs";

const APPEND_URL = `/${isSuperuser() ? "super" : "client"}/businesscardorders`;

const App: React.FC = () => {
  const [message, setMessage] = useState<Message | null>(null);
  const [orders, setOrders] = useState<BusinessCardOrder[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let messageContent;
  if (message !== null) {
    messageContent = getMessageContent(message);
  }

  const refresh = () => {
    getWithAuthHeader(`${APPEND_URL}`)
      .then((response) => {
        console.log(response.data.data);
        setOrders(response.data.data);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          setMessage({
            message: error.response?.data.message,
            severity: Severity.Error,
          });
        }
      });
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      <div>
        {/* Hero Section */}
        <div className={`heroSectionBusinessCard hero-section`}>
          <div className="hero-content top-margin">
            <h1>Business Card Ordering Service</h1>
            <p>
              Order your personalised business cards online and get them
              delivered to your door. The QR code will send customers directly
              to your booking page.
            </p>
             {/* eslint-disable-next-line */}
            <a href='#' className="search-button" onClick={toggleModal}>
            Show Example
          </a>
          </div>
        </div>
        {messageContent}
        {/* <h1 className="details text-3xl font-bold">Business Card Orders {(orders && orders?.length > 0) && "- "+ orders.length}</h1> */}
        <BusinessCardOrderList items={orders} refresh={refresh} />
      </div>

      {/* Modal for Image Preview */}
      {isModalOpen && (
        <div className="ride-with-us-modal" onClick={toggleModal}>
          <div
            className="ride-with-us-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            {isMobile() ? 
            <table className="example-table">
            <tr>
              <td>
                <p>Front</p>
                <img
                  src={require("./Business card example_front.jpg")}
                  alt="Personal Booking Page Example"
                  className="ride-with-us-modal-image"
                />
              </td>
              </tr>

              <tr>
              <td>
                <p>Back</p>
                <img
                  src={require("./Business card example_back.jpg")}
                  alt="Personal Booking Page Example"
                  className="ride-with-us-modal-image"
                />
              </td>
            </tr>
          </table> :
          <table className="example-table">
          <tr>
            <td>
              <p>Front</p>
              <img
                src={require("./Business card example_front.jpg")}
                alt="Personal Booking Page Example"
                className="ride-with-us-modal-image"
              />
            </td>
            <td>
              <p>Back</p>
              <img
                src={require("./Business card example_back.jpg")}
                alt="Personal Booking Page Example"
                className="ride-with-us-modal-image"
              />
            </td>
          </tr>
        </table> 
            }
         <p className="description">* When you card template is ready, an example will first be sent to you. If you do not agree with some details there, you can request it to our support team.</p>

            
            <button className="close-button" onClick={toggleModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
