import React, { ChangeEvent, useState } from "react";
import "./DriverList.css";
import { Driver } from "../../Types/Vehicle";
import Modal from "@/components/Modal/Modal";
import { AxiosError } from "axios";
import DriverItem from "./DriverItem";
import {
  delWithAuthHeader,
  patchWithAuthHeader,
  postWithAuthHeader,
} from "@/utils/apiUtils";
import Button from "@/components/common/Button";
import { Message } from "@/components/Account/types/Message";
import Severity from "@/components/common/types/Severity";
import { ActionProps } from "../../Types/ActionProps";
import { ButtonType } from "../../Types/ButtonType";
import { isMobile } from "@/utils/globalFuncs";
import DriverTable from "./DriverTable";
import DriverListMobile from "./DriverListMobile";
import NoAvailableDataComponent from "../NoAvailableDataComponent";

const APPEND_URL = "/client/fleet/driver";

interface IMyProps {
  items: Driver[] | undefined;
  refresh: Function;
  setParentMessage?: Function;
}

const initDriver: Driver = {
  firstName: "",
  lastName: "",
};

const DriverList: React.FC<IMyProps> = (props: IMyProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [driver, setDriver] = useState<Driver>(initDriver);
  const [modalMessage, setModalMessage] = useState<Message>();
  const [doCreate, setDoCreate] = useState(false);
  const [doUpdate, setDoUpdate] = useState(false);
  const [doDelete, setDoDelete] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    setModalMessage({}); // Clear message
  };

  const closeModal = () => {
    setDriver(initDriver);
    setIsOpen(false);
    setDoCreate(false);
    setDoDelete(false);
    setDoUpdate(false);
    setModalMessage(undefined);
  };

  const handleSelect = (item: Driver) => {
    console.log(item);
    setDriver(item);
    openModal();
  };

  const handleNew = (driver: Driver) => {
    setDriver(initDriver);
    openModal();
  };

  const handleDelete = async () => {
    if (driver && driver.id) {
      await delWithAuthHeader(`${APPEND_URL}/${driver.id}`)
        .then((response) => {
          if (response.data.data === true) {
            closeModal();
            props.refresh(false, driver.id);
            if (props.setParentMessage) {
              props.setParentMessage({
                message: `Driver with id '${driver.id}' deleted successfuly`,
                severity: Severity.Info,
              }); // Clear parent message
            }
          }
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const deleteAction: ActionProps = {
    name: "Delete",
    method: handleDelete,
    buttonType: ButtonType.delete,
  };

  const handleUpdate = async () => {
    console.log(driver);
    if (driver && driver.id) {
      await patchWithAuthHeader(`${APPEND_URL}`, driver)
        .then(() => {
          props.refresh(true);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const updateAction: ActionProps = {
    name: "Update",
    method: handleUpdate,
    buttonType: ButtonType.primary,
  };

  const handleCreate = async () => {
    if (driver && driver.firstName !== "" && driver.lastName !== "") {
      await postWithAuthHeader(`${APPEND_URL}`, driver)
        .then(() => {
          props.refresh(true);
          setDriver(initDriver);
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            setModalMessage({
              message: error.response?.data.message,
              severity: Severity.Error,
            });
          }
        });
    }
  };

  const createAction: ActionProps = {
    name: "Create",
    method: handleCreate,
    buttonType: ButtonType.primary,
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setDriver({
      ...driver,
      [e.target.name]: e.target.value,
    });
  };

  const actions: ActionProps[] = [];
  if (doCreate) {
    actions.push(createAction);
  }
  if (doUpdate) {
    actions.push(updateAction);
  }
  if (doDelete) {
    actions.push(deleteAction);
  }

  return (
    <div>
      <div className="add-new-button">
        <p>
          <b>{props.items && props.items.length} </b>
          {props?.items?.length === 1 ? "Driver" : "Drivers"}
        </p>
        <Button
          onClick={() => {
            setDoCreate(true);
            handleNew(driver);
          }}
        >
          Add Driver
        </Button>
      </div>
      {props.items && props.items?.length > 0 ? (
        isMobile() ? (
          <DriverListMobile
            details={props.items ?? []}
            handleSelect={handleSelect}
            setDoUpdate={setDoUpdate}
            setDoDelete={setDoDelete}
          ></DriverListMobile>
        ) : (
          <DriverTable
            details={props.items ?? []}
            handleSelect={handleSelect}
            setDoUpdate={setDoUpdate}
            setDoDelete={setDoDelete}
          ></DriverTable>
        )
      ) : (
        <NoAvailableDataComponent />
      )}
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        actions={actions}
        title={"Driver Details"}
        message={modalMessage}
      >
        {driver && (
          <DriverItem item={driver} handleInputChange={handleInputChange} />
        )}
      </Modal>
    </div>
  );
};

export default DriverList;
