import React from "react";
import "./ClientList.css"; // Assuming styles are added here
import logo from "./logo512.png"; // Default fallback image
import SearchIcon from "@mui/icons-material/Search";

export interface ClientAbstract {
  uniqueIdentifier: string;
  companyName: string;
  city: string;
  country: string;
}

interface ClientListProps {
  clients: ClientAbstract[];
  filter: string;
  handleFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoadingClients: boolean;
}

const ClientList: React.FC<ClientListProps> = ({
  clients,
  filter,
  handleFilterChange,
  isLoadingClients,
}) => {
  // Helper function to get the image path or fallback to the logo
  const getClientImage = (clientIdentifier: string): string => {
    try {
      return require(`@common/${clientIdentifier}/${clientIdentifier}.png`);
    } catch {
      return logo; // Default fallback image
    }
  };

  const filteredClients = clients.filter(
    (client) =>
      client.uniqueIdentifier.toLowerCase().includes(filter) ||
      client.companyName.toLowerCase().includes(filter) ||
      client.city?.toLowerCase().includes(filter) ||
      client.country.toLowerCase().includes(filter)
  );

  const clientCountryCity = (country: string, city: string) => {
    const countryCity = city + ", " + country;
    console.log(country, city);
    return country && city
      ? countryCity
      : country
      ? country
      : city
      ? city
      : null;
  };
  return (
    <div>
      {clients.length > 0 ? (
        <div>
          <p className="functionality-availability">
            Currently, this functionality is only available for specific
            clients. Please use a client's unique link to access the
            application.
          </p>
          <h2>Clients</h2>
          <div className="search-input-container">
            <SearchIcon></SearchIcon>
            <input
              type="text"
              placeholder="Type here..."
              value={filter}
              onChange={handleFilterChange}
              className="filter-input"
            />
          </div>
          <div className="search-container">
            <p className="search-items-number">
              <b>{filteredClients ? filteredClients.length : 0}</b>{" "}
              {filteredClients && filteredClients.length === 1
                ? "client"
                : "clients"}{" "}
              found
            </p>
          </div>
          <div className="client-grid">
            {filteredClients.map((client) => (
              <div key={client.uniqueIdentifier} className="client-card">
                <img
                  src={getClientImage(client.uniqueIdentifier)}
                  alt={client.companyName}
                  className="client-image"
                />
                <div className="client-details">
                  <h3>{client.companyName}</h3>
                  {clientCountryCity(client.country, client.city) && (
                    <p className="description">
                      {clientCountryCity(client.country, client.city)}
                    </p>
                  )}
                  <a
                    href={`/new-booking?client=${client.uniqueIdentifier}`}
                    className="book-button"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : isLoadingClients ? (
        <></>
      ) : (
        <p className="text">
          No Clients Available right now. Please try again later.
        </p>
      )}
    </div>
  );
};

export default ClientList;
