import { Ride } from "./Ride";
import { PaymentStatus } from "./Status";

interface Passenger {
    priceEuro: number;
    id?: number;
    firstName?: string;
    lastName?: string;
    people?: number;
    luggage?: number;
    email?: string;
    rideId?: number;
    //TODO: Use enum
    status: string;
    paymentStatus: PaymentStatus;
    active: boolean;
    ride: Ride;
    original: boolean;
    notes: string;
    priceOverrideStatus: PriceOverrideStatus;
  
  }

  export enum PriceOverrideStatus {
    DRIVER_REQUESTED = "Driver Requested",
    PASSENGER_REJECTED = "Passenger Rejected",
    PASSENGER_ACCEPTED = "Passenger Accepted",
  }

  export default Passenger;