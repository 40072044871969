import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import "../Tariff/TableListMobile.css";
import { Location } from "../../Types/Location";

interface LocationListMobileProps {
  details: Location[];
  handleSelect: Function;
  setDoUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setDoDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationListMobile: React.FC<LocationListMobileProps> = (tableData) => {
  return (
    <div className="table-list-container-mobile">
      <h4 className="table-title">
        <b>Locations</b>
      </h4>
      <div className="table-list-mobile">
        {tableData.details.map((props: Location) => {
          const handleSelect = (e: { preventDefault: () => void }) => {
            e.preventDefault();
            tableData.handleSelect(props);
          };
          return (
            <div className="table-list-item-container" key={props.id}>
              <div className="table-list-item">
                <div>
                  <p className="list-item-label">Location</p>
                  <p>
                    <b>{props.location_name}</b>
                  </p>
                </div>
                <div className="item-justify-end">
                  <p className="list-item-label">Airport</p>
                  <p>
                    <b>{props.airportInd ? "Yes" : "No"}</b>
                  </p>
                </div>
              </div>
              <div className="table-list-action-buttons">
                <div>
                  <ModeEditIcon
                    onClick={(e) => {
                      tableData.setDoUpdate(true);
                      handleSelect(e);
                    }}
                  ></ModeEditIcon>
                </div>
                <div>
                  <DeleteIcon
                    onClick={(e) => {
                      tableData.setDoDelete(true);
                      handleSelect(e);
                    }}
                  ></DeleteIcon>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LocationListMobile;
