import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import "../Tariff/TableListMobile.css";
import { Vehicle } from "../../Types/Vehicle";

interface VehicleTableProps {
  details: Vehicle[];
  handleSelect: Function;
  setDoUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setDoDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const VehicleListMobile: React.FC<VehicleTableProps> = (tableData) => {
  return (
    <div className="table-list-container-mobile">
      <h4 className="table-title">
        <b>Vehicles</b>
      </h4>
      <div className="table-list-mobile">
        {tableData.details.map((props: Vehicle) => {
          const handleSelect = (e: { preventDefault: () => void }) => {
            e.preventDefault();
            tableData.handleSelect(props);
          };
          return (
            <div className="table-list-item-container" key={props.id}>
              <div className="table-list-item">
                <div>
                  <p className="list-item-label">Licence Plate</p>
                  <p>
                    <b>{props.licencePlate}</b>
                  </p>
                </div>
                <div className="item-justify-end">
                  <p className="list-item-label">Vehicle Model </p>
                  <p>
                    <b>
                      {props.make} {props.model}
                    </b>
                  </p>
                </div>
                <div>
                  <p className="list-item-label">Driver</p>
                  <p>
                    <b>
                      {props.driver?.firstName} {props.driver?.lastName}
                    </b>
                  </p>
                </div>
              </div>
              <div className="table-list-action-buttons">
                <div>
                  <ModeEditIcon
                    onClick={(e) => {
                      tableData.setDoUpdate(true);
                      handleSelect(e);
                    }}
                  ></ModeEditIcon>
                </div>
                <div>
                  <DeleteIcon
                    onClick={(e) => {
                      tableData.setDoDelete(true);
                      handleSelect(e);
                    }}
                  ></DeleteIcon>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VehicleListMobile;
