import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

interface TimePickerProps {
  initHour?: string;
  initMinute?: string;
  handleTimepickerChange: (hour: string, minute: string) => void;
}
const BasicTimePicker: React.FC<TimePickerProps> = (props: TimePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker"]}>
        <TimePicker
          defaultValue={dayjs().hour(parseInt(props.initHour || "0", 10)).minute(parseInt(props.initMinute || "0", 10))}
          onChange={(time) => {
            if (time) {
              props.handleTimepickerChange(
                time.hour().toString(),
                time.minute().toString()
              );
            }
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default BasicTimePicker;
