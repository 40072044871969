import Button from "@common/Button";
import "./ClientBookings.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ClientBookingProps } from "@/components/ShareARide/Types/ClientBookingProps";
import { Ride } from "@/components/ShareARide/Types/Ride";
import { Period } from "@/enums/PeriodEnum";
import { formatDate, formatTime24Hour } from "@/utils/globalFuncs";
import ScheduleDetails from "./ScheduleDetails";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { PriorityHigh } from "@mui/icons-material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const ClientBookingMobile: React.FC<ClientBookingProps> = ({
  today,
  yesterday,
  tomorrow,
  completeAction,
  rejectAction,
  acceptAction,
  ridesOnView,
  periodOnView,
  setPeriodOnView,
  setShowOnlyClientDetails,
  setRide,
  setIsModalOpen,
  setDoAccept,
  setDoComplete,
  setDoReject,
  setShowConfirmationModal,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isScheduleOpen, setScheduleOpen] = useState<boolean>(false);
  const [isOpenFront, setIsOpenFront] = useState<boolean>(false);

  const RideStatusIcon = (rideStatus: string) => {
    switch (rideStatus) {
      case "REJECTED":
        return <ClearIcon></ClearIcon>;
      case "ACCEPTED":
        return <CheckIcon></CheckIcon>;
      case "UNCONFIRMED":
        return <PriorityHigh></PriorityHigh>;
      default:
        return <TaskAltIcon></TaskAltIcon>;
    }
  };

  const RideItem = (ride: Ride, hasDate?: boolean) => {
    return (
      <div className="ride-item" key={ride.id}>
        <div className="itinerary-container">
          <div className={`circle-icon circle-${ride.status}`}>
            {RideStatusIcon(ride.status)}
          </div>
          {hasDate ? (
            <div className="itinerary-info">
              <div className="top">
                <b>{formatDate(ride.date).date}</b>
              </div>
              <div className="bottom">{formatDate(ride.date).day}</div>
            </div>
          ) : (
            ""
          )}
          <div className="itinerary-info">
            <div className="top">
              <b>{ride.destinationFrom?.location_name}  {ride.destinationFromAddress}</b>
            </div>
            <div className="bottom">{ride.destinationTo?.location_name}  {ride.destinationToAddress}</div>
          </div>
        </div>
        <div className="hour">
          <b>{formatTime24Hour(ride.hour, ride.minute)}</b>
        </div>
      </div>
    );
  };
  const RidesListUnconfirmed = (ridesOnView: Ride[]) => {
    return ridesOnView
      .filter((ride) => ride.status === "UNCONFIRMED")
      .map((ride: Ride) => (
        <div className="ride-item-container" key={ride.id}>
          {RideItem(ride, true)}
          <div className="ride-item-buttons">
            <Button
              type="ride"
              onClick={() => {
                setShowOnlyClientDetails(false);
                setRide(ride);
                setIsModalOpen(true);
                setDoAccept(true);
                setDoReject(false);
                setDoComplete(false);
              }}
            >
              <b>Accept</b>
            </Button>
            <Button
              type="ride"
              onClick={() => {
                setShowOnlyClientDetails(false);
                setRide(ride);
                setIsModalOpen(true);
                setDoReject(true);
                setShowConfirmationModal(true);
                setDoAccept(false);
                setDoComplete(false);
              }}
            >
              <b>Reject</b>
            </Button>
          </div>
        </div>
      ));
  };

  const RidesList = (ridesOnView: Ride[]) => {
    return ridesOnView
      .filter((ride) => ride.status !== "UNCONFIRMED")
      .map((ride: Ride) => (
        <div
          className="ride-item-container"
          key={ride.id}
          onClick={() => {
            setShowOnlyClientDetails(false);
            setRide(ride);
            setIsModalOpen(true);
            setDoComplete(true);
            setDoAccept(true);
            setDoReject(true);
          }}
        >
          {RideItem(ride)}
        </div>
      ));
  };
  return (
    <div className="client-booking-mobile-container">
      {
        <ScheduleDetails
          isScheduleOpen={isScheduleOpen}
          setScheduleOpen={setScheduleOpen}
        ></ScheduleDetails>
      }

      <Button
        customClassName="schedule-title-mobile"
        onClick={() => setScheduleOpen(!isScheduleOpen)}
      >
        <b>Statistics</b>
      </Button>
      <div className="timeline mobile">
        <div
          className="timeline-item"
          onClick={() => setPeriodOnView(Period.past)}
        >
          <div>{Period.past}</div>
          <div
            className={`timeline-icon mobile ${
              Period.past === periodOnView ? " selected" : ""
            }`}
          >
            <ArrowBackIosIcon></ArrowBackIosIcon>
          </div>
        </div>
        <div
          className="timeline-item"
          onClick={() => setPeriodOnView(Period.yesterday)}
        >
          <div>{Period.yesterday}</div>
          <div
            className={`timeline-icon mobile ${
              Period.yesterday === periodOnView ? " selected" : ""
            }`}
          >
            {yesterday}
          </div>
        </div>
        <div
          className="timeline-item"
          onClick={() => setPeriodOnView(Period.today)}
        >
          <div>{Period.today}</div>
          <div
            className={`timeline-icon mobile ${
              Period.today === periodOnView ? " selected" : ""
            }`}
          >
            {today}
          </div>
        </div>
        <div
          className="timeline-item"
          onClick={() => setPeriodOnView(Period.tomorrow)}
        >
          <div>{Period.tomorrow}</div>
          <div
            className={`timeline-icon mobile ${
              Period.tomorrow === periodOnView ? " selected" : ""
            }`}
          >
            {tomorrow}
          </div>
        </div>
        <div
          className="timeline-item"
          onClick={() => setPeriodOnView(Period.future)}
        >
          <div>{Period.future}</div>
          <div
            className={`timeline-icon mobile ${
              Period.future === periodOnView ? " selected" : ""
            }`}
          >
            <ArrowForwardIosIcon></ArrowForwardIosIcon>
          </div>
        </div>
      </div>
      <div className="all-rides mobile">
        <div className={`rides-container back ${isOpen ? "open" : ""}`}>
          <h4
            className="rides-title pending"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <b>Pending</b>
            {!isOpen ? (
              <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            ) : (
              <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
            )}
          </h4>
          <div className="rides-list">
            {ridesOnView &&
            ridesOnView.filter((ride) => ride.status === "UNCONFIRMED").length >
              0 ? (
              RidesListUnconfirmed(ridesOnView)
            ) : (
              <p className="p-4">{`No Pending Rides available for ${periodOnView}`}</p>
            )}
          </div>
        </div>
        <div className={`rides-container front ${isOpenFront ? "open" : ""}`}>
          <h4
            className="rides-title"
            onClick={() => {
              setIsOpenFront(!isOpenFront);
            }}
          >
            <b>{periodOnView}</b>
            {isOpenFront ? (
              <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            ) : (
              <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
            )}
          </h4>
          <div className="rides-list">
            {ridesOnView &&
            ridesOnView.filter((ride) => ride.status !== "UNCONFIRMED").length >
              0 ? (
              RidesList(ridesOnView)
            ) : (
              <p className="p-4">{`No Rides available for ${periodOnView}`}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientBookingMobile;
