import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import "../Tariff/Table.css";
import { BusinessCardOrder } from "../../Types/BusinessCardOrder";
import { isSuperuser } from "@/utils/authUtils";

interface BusinessCardOrderTableProps {
  details: BusinessCardOrder[];
  handleSelect: Function;
  setDoUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setDoDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const BusinessCardOrderTable: React.FC<BusinessCardOrderTableProps> = (
  tableData
) => {
  return (
    <div className="table">
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Cards Quantity</th>
            <th>Price</th>
            <th>Order Status</th>
            {isSuperuser() ? <th>Client</th> : <th className="no-border"></th>}
            <th className="no-border"></th>
            <th className="no-border"></th>
            <th className="no-border"></th>
          </tr>
        </thead>
        <tbody>
          {tableData.details.map((props: BusinessCardOrder) => {
            const handleSelect = (e: { preventDefault: () => void }) => {
              e.preventDefault();
              tableData.handleSelect(props);
            };
            return (
              <tr key={props.id}>
                <td>{props.quantity}</td>
                <td>{props.priceEuro} €</td>
                <td>{props.orderStatus}</td>
                {isSuperuser() ? <td>{props.client?.companyName}</td> : <td></td>}
                <td className="type-UNCONFIRMED">
                  {props.cancelled ? "CANCELLED" : ""}
                </td>
                <td className="action-button-container">
                  <div className="action-button">
                    <ModeEditIcon
                      onClick={(e) => {
                        tableData.setDoUpdate(true);
                        handleSelect(e);
                      }}
                    ></ModeEditIcon>
                  </div>
                </td>
                <td className="action-button-container">
                  <div className="action-button">
                    <DisabledByDefaultIcon
                      onClick={(e) => {
                        tableData.setDoDelete(true);
                        handleSelect(e);
                      }}
                    ></DisabledByDefaultIcon>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BusinessCardOrderTable;
