import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { Message } from "@/components/Account/types/Message";
import { getMessageContent } from "@/components/Account/accountUtilities";

import "./Modal.css";
import { ActionProps } from "../ShareARide/Types/ActionProps";
import { ButtonType } from "../ShareARide/Types/ButtonType";

interface ModalProps {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  children: any;
  actions?: ActionProps[];
  cancelAction?: Function;
  deleteAction?: Function;
  message?: Message;
}

const Modal: React.FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  children,
  actions,
  cancelAction,
  deleteAction,
  message,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const closeModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
      onClose();
    }, 300); // Adjust the time as needed to match your CSS animation duration
  };

  if (!isOpen && !isAnimating) return null;

  let messageContent;
  if (message) {
    messageContent = getMessageContent(message);
  }

  return (
    <>
      <div className="darkBG" onClick={closeModal} />
      <div className="centered">
        <div className="modalH">
          <div className="modalHeader">
            <h5 className="heading">{title}</h5>
          </div>
          <button className={"closeBtn"} onClick={closeModal}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          {messageContent}
          <div className="modalContent">{children}</div>
          <div className="modalActions">
            <div className="actionsContainer">
              {actions?.map((action) => (
                <button
                  key={action.name}
                  className={`${action.buttonType} ${
                    action.disabled ? "disabled" : ""
                  }`}
                  onClick={() => action.method()}
                  disabled={action.disabled}
                >
                  {action.name}
                </button>
              ))}
              {deleteAction && (
                <button
                  className={ButtonType.delete}
                  onClick={() => deleteAction}
                >
                  Delete
                </button>
              )}
              <button
                className={ButtonType.cancel}
                onClick={cancelAction ? () => cancelAction : closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
