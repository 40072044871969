import Button from "@common/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ClientBookingProps } from "@/components/ShareARide/Types/ClientBookingProps";
import { Ride } from "@/components/ShareARide/Types/Ride";
import Passenger from "@/components/ShareARide/Types/Passenger";
import { Period } from "@/enums/PeriodEnum";
import { PaymentStatus } from "@/enums/PaymentStatus";
import {
  findPassengerOriginal,
  formatDate,
  formatTime12Hour,
} from "@/utils/globalFuncs";
import ScheduleDetails from "./ScheduleDetails";

const ClientBookingDesktop: React.FC<ClientBookingProps> = ({
  today,
  yesterday,
  tomorrow,
  ridesOnView,
  periodOnView,
  setIsModalOpen,
  setRide,
  setPeriodOnView,
  setShowOnlyClientDetails,
  setDoAccept,
  setDoComplete,
  setDoReject,
  setShowConfirmationModal,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenFront, setIsOpenFront] = useState<boolean>(false);

  const Passenger = (passengers: Passenger[]) => {
    const passengerOriginalFound = findPassengerOriginal(passengers);
    return (
      <div
        className="grey section-ride-title"
        key={passengerOriginalFound.id?.toString()}
      >
        {passengerOriginalFound.firstName} {passengerOriginalFound.lastName}
        {passengerOriginalFound.status === "UNCONFIRMED" && (
          <div className={`type-${passengerOriginalFound.status}`}>
            {passengerOriginalFound.status}
          </div>
        )}
      </div>
    );
  };

  const RidesListUnconfirmed = (ridesOnView: Ride[]) => {
    return ridesOnView
      .filter((ride) => ride.status === "UNCONFIRMED")
      .map((ride: Ride) => (
        <div className="ride-item-container-desktop" key={ride.id}>
          <div className="ride-item-desktop">
            <div className="itinerary-info">
              <div className="month">
                <div>
                  <b>{formatDate(ride.date).date}</b>
                </div>
                <div className="grey">
                  <b> {formatDate(ride.date).day}</b>
                </div>
              </div>
              <div className="itinerary">
                <div>
                  <b>{ride.destinationFrom?.location_name}</b>
                </div>
                <div className="grey">
                  <b>{ride.destinationTo?.location_name}</b>
                </div>
              </div>
            </div>
            <div className="time">
              <div>
                <b>Pick up time</b>
              </div>
              <div>
                <b>{formatTime12Hour(ride.hour, ride.minute)}</b>
              </div>
            </div>
          </div>
          <div className="ride-item-desktop">
            <div>
              <b>Passenger</b>
            </div>
            <div className="grey">
              <b> {Passenger(ride.passengers)}</b>
            </div>
            <Button
              type="accept"
              onClick={() => {
                setShowOnlyClientDetails(true);
                setRide(ride);
                setIsModalOpen(true);
              }}
            >
              Client Details
            </Button>
          </div>
          <div className="ride-item-desktop">
            <div>
              <b>Accept the ride or share to your drivers</b>
            </div>
            <div>
              <Button
                type="accept"
                onClick={() => {
                  setShowOnlyClientDetails(false);
                  setRide(ride);
                  setIsModalOpen(true);
                  setDoAccept(true);
                  setDoComplete(false);
                  setDoReject(false);
                }}
              >
                Accept
              </Button>
              <Button
                type="decline"
                onClick={() => {
                  setShowOnlyClientDetails(false);
                  setRide(ride);
                  setIsModalOpen(true);
                  setDoAccept(false);
                  setDoComplete(false);
                  setDoReject(true);
                  setShowConfirmationModal(true);
                }}
              >
                Reject
              </Button>
            </div>
          </div>
        </div>
      ));
  };

  const RidesList = (ridesOnView: Ride[]) => {
    return ridesOnView
      .filter((ride) => ride.status !== "UNCONFIRMED")
      .map((ride: Ride) => (
        <div className="ride-item-container-desktop" key={ride.id}>
          <div className="ride-item-desktop">
            <div className="section-ride-title">Ride summary</div>
            <div className="ride-item-info">
              <div>Pick up location:</div>
              <div>{ride.destinationFrom?.location_name} {ride.destinationFromAddress}</div>
            </div>
            <div className="ride-item-info">
              <div>Drop off location:</div>
              <div>{ride.destinationTo?.location_name}  {ride.destinationToAddress}</div>
            </div>
            <div className="ride-item-info">
              <div>Ride distance:</div>
              <div>... km / Aprox, ... minutes</div>
            </div>
            <div className="ride-item-info">
              <div className={`type-${ride.status}`}>{ride.status}</div>
              <div>{formatTime12Hour(ride.hour, ride.minute)}</div>
            </div>
            <div className="ride-item-info">
              <div>Payment Status:</div>
              <div className="payment-status">
                {findPassengerOriginal(ride.passengers).paymentStatus ===
                PaymentStatus.paid.toString() ? (
                  <span className="highlighted">{PaymentStatus.paid}</span>
                ) : (
                  <span>{PaymentStatus.unpaid}</span>
                )}
              </div>
            </div>
          </div>
          <div className="ride-item-desktop">
            <div className="section-ride-title">Passenger</div>
            {Passenger(ride.passengers)}
            <Button
              onClick={() => {
                setShowOnlyClientDetails(false);
                setRide(ride);
                setIsModalOpen(true);
                // setDoAccept(ride.status === "ACCEPTED" || ride.status === "COMPLETED" ? false: true);
                setDoAccept(true);
                setDoComplete(true);
                setDoReject(true);
              }}
              type="accept"
            >
              View More
            </Button>
          </div>
          <div className="ride-item-desktop">
            <div className="section-ride-title">Additional Preferences</div>
            <div className="ride-item-info">
              <div>Baby Seat requested:</div>
              <div>
                <span className="highlighted">NO</span>
              </div>
            </div>
            <div className="ride-item-info">
              <div>Bottle of water:</div>
              <div>
                <span className="highlighted">Not Included</span>
              </div>
            </div>
            <div className="ride-item-info">
              <div>Notes:</div>
              <div>{ride.passengers[0].notes}</div>
            </div>
            <div className="section-ride-title">Estimated Fare</div>
            <div>{ride.passengers[0].priceEuro} euros</div>
          </div>
        </div>
      ));
  };

  return (
    <div className="scheduled-rides-container">
      <ScheduleDetails></ScheduleDetails>
      <div className="all-rides-container">
        <div className="all-rides">
          <div className="timeline desktop">
            <div
              className={`timeline-item ${
                Period.past === periodOnView ? "x-large" : "large"
              }`}
              onClick={() => setPeriodOnView(Period.past)}
            >
              <div>{Period.past}</div>
              <div className="timeline-icon">
                <ArrowBackIosIcon></ArrowBackIosIcon>
              </div>
            </div>
            <div
              className={`timeline-item ${
                Period.yesterday === periodOnView ? "x-large" : "larger"
              }`}
              onClick={() => setPeriodOnView(Period.yesterday)}
            >
              <div>{Period.yesterday}</div>
              <div className="timeline-icon selected">{yesterday}</div>
            </div>
            <div
              className={`timeline-item ${
                Period.today === periodOnView ? "x-large" : "larger"
              }`}
              onClick={() => setPeriodOnView(Period.today)}
            >
              <div>{Period.today}</div>
              <div className="timeline-icon">{today}</div>
            </div>
            <div
              className={`timeline-item ${
                Period.tomorrow === periodOnView ? "x-large" : "larger"
              }`}
              onClick={() => setPeriodOnView(Period.tomorrow)}
            >
              <div>{Period.tomorrow}</div>
              <div className="timeline-icon">{tomorrow}</div>
            </div>
            <div
              className={`timeline-item ${
                Period.future === periodOnView ? "x-large" : "large"
              }`}
              onClick={() => setPeriodOnView(Period.future)}
            >
              <div>{Period.future}</div>
              <div className="timeline-icon">
                <ArrowForwardIosIcon></ArrowForwardIosIcon>
              </div>
            </div>
          </div>

          <div
            className={`rides-container desktop back ${isOpen ? "open" : ""}`}
          >
            <h4
              className="rides-title pending"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <b>Pending</b>
              {!isOpen ? (
                <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
              ) : (
                <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
              )}
            </h4>
            <div className="rides-list">
              <p>
                <b>New request:</b>
              </p>
              {ridesOnView &&
              ridesOnView.filter((ride) => ride.status === "UNCONFIRMED")
                .length > 0
                ? RidesListUnconfirmed(ridesOnView)
                : `No Pending Rides available for ${periodOnView}`}
            </div>
          </div>
          <div
            className={`rides-container desktop front ${
              isOpenFront ? "open" : ""
            }`}
          >
            <h4
              className="rides-title"
              onClick={() => {
                setIsOpenFront(!isOpenFront);
              }}
            >
              <b>{periodOnView}</b>
              {isOpenFront ? (
                <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
              ) : (
                <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
              )}
            </h4>
            <div className="rides-list">
              <p>
                <b>Your rides:</b>
              </p>
              {ridesOnView &&
              ridesOnView.filter((ride) => ride.status !== "UNCONFIRMED")
                .length > 0
                ? RidesList(ridesOnView)
                : `No Rides available for ${periodOnView}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientBookingDesktop;
