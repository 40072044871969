import { Routes, Route, Link, useLocation } from "react-router-dom";
import AccountSettings from "@components/Account/AccountSettings";
import NoMatch from "@components/ErrorLandingPages/NoMatch";
import AddressPage from "@components/Address/AddressPage";
import Dashboard from "@components/Home/Dashboard";
import Login from "@/components/LoginRegister/LoginRegister";
import {
  Authority,
  isAuthenticated,
  tokenHasAuthority,
} from "@utils/authUtils";
import { useEffect, useState } from "react";
import withAuthentication from "@components/common/AuthenticatedPage";
import withAuthorization from "@components/common/AuthorizedPage";
import { useAppDispatch } from "@store/hooks";
import { clearAccounts } from "@components/Account/accountSlice";
import { clearAddresses } from "@components/Address/addressSlice";
import Contacts from "../Address/Contacts";
import ShareARide from "../ShareARide/ShareARide";
import ManageMyBooking from "../ShareARide/ManageMyBooking";
import FleetManagement from "../ShareARide/Client/FleetManagement";
import BusinessCardOrders from "../ShareARide/Client/BusinessCardOrders";
import TariffManagement from "../ShareARide/Client/TariffManagement";
import BookATrip from "../ShareARide/BookATrip/BookATrip";
import ClientBookings from "../Management/Bookings/ClientBookings";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./Navbar.css";
import ClientManagement from "../ShareARide/Client/ClientManagement";
import { FaUserCircle } from "react-icons/fa"; // Import the icon
import RideWithUs from "../LandingPage/RideWithUs";
import { Message } from "../Account/types/Message";
import { getMessageContent } from "../Account/accountUtilities";
import Severity from "../common/types/Severity";

const Navbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [authenticated, setAuthenticated] = useState(false); // Initially false
  const [authorized, setAuthorized] = useState(false); // Manage authorization state
  const [superuser, setSuperuser] = useState(false); // Manage authorization state
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<Message>(process.env.REACT_APP_NAVBAR_MESSAGE?
    {message: process.env.REACT_APP_NAVBAR_MESSAGE, 
      severity: process.env.REACT_APP_NAVBAR_MESSAGE_SEVERITY === "WARN" ? Severity.Warning : Severity.Info}
      : {}
  );

  let messageContent;
  if (message) {
    messageContent = getMessageContent(message);
  }

    // Update navbar state when authentication changes
    useEffect(() => {
      const userAuthenticated = isAuthenticated();
      setAuthenticated(userAuthenticated);
      setAuthorized(userAuthenticated && tokenHasAuthority(Authority.ROLE_BACKOFFICE));
      setSuperuser(userAuthenticated && tokenHasAuthority(Authority.ROLE_SUPERUSER));
    }, [location]); // React to location changes (i.e., after login/logout)

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  if (!authenticated) {
    dispatch(clearAccounts());
    dispatch(clearAddresses());
  }

  const handleLoginClick = () => {
    if (!authenticated) {
      // Handle logout logic here (if necessary)
      setAuthenticated(false);
      setAuthorized(false);
    }
  };

  const isActive = (path: string) => location.pathname === path;

  // Authenticated Pages
  const AddressPageWithAuthentication = withAuthentication(AddressPage);
  const ContactsWithAuthentication = withAuthentication(Contacts);
  const DashboardWithAuthentication = withAuthentication(Dashboard);
  const AccountSettingsWithAuthentication = withAuthentication(AccountSettings);

  // Authorization Pages
  const ClientManagementWithAuthorization = withAuthorization(ClientManagement);
  const FleetManagementWithAuthorization = withAuthorization(FleetManagement);
  const BusinessCardOrdersWithAuthorization = withAuthorization(BusinessCardOrders);
  const TariffManagementWithAuthorization = withAuthorization(TariffManagement);
  const ClientBookingsWithAuthorization = withAuthorization(ClientBookings);

  return (
    <div className="container-fluid">
    <nav className="navbar navbar-expand-lg bg">
      <Link to="/" className="navbar-brand">
        <img
          src={require("./transfero_logo.png")}
          style={{ marginLeft: "5%", borderRadius: "15px" }}
          width="208"
          height="40"
          className="d-inline-block align-top"
          alt="logo"
        />
      </Link>
              {/* Toggle Button */}
              <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded={isNavbarOpen}
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              to="/home"
              className={`nav-link ${isActive("/home") ? "active" : ""}`}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/new-booking"
              className={`nav-link ${isActive("/new-booking") ? "active" : ""}`}
            >
              New Booking
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/ride-with-us"
              className={`nav-link ${isActive("/ride-with-us") ? "active" : ""}`}
            >
              Ride With Us
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              to="/booking"
              className={`nav-link ${isActive("/booking") ? "active" : ""}`}
            >
              Manage Your Booking
            </Link>
          </li> */}
          
            {authenticated && <li className="nav-item dropdown">
              {/* eslint-disable-next-line */}              
              <a
                className="nav-link-management nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Management
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <Link
                  to="/client/client-management"
                  className={`dropdown-item ${
                    isActive("/client/client-management") ? "active" : ""
                  }`}
                >
                  Client
                </Link>
                <Link
                  to="/client/ride-management"
                  className={`dropdown-item ${
                    isActive("/client/ride-management") ? "active" : ""
                  }`}
                >
                  Rides
                </Link>
                <Link
                  to="/client/fleet-management"
                  className={`dropdown-item ${
                    isActive("/client/fleet-management") ? "active" : ""
                  }`}
                >
                  Fleet
                </Link>
                <Link
                  to="/client/tariff-management"
                  className={`dropdown-item ${
                    isActive("/client/tariff-management") ? "active" : ""
                  }`}
                > Tariffs
       </Link>
       <Link
                  to="/client/business-card-order"
                  className={`dropdown-item ${
                    isActive("/client/business-card-orders") ? "active" : ""
                  }`}
                > Business Card Orders
       </Link>
       {superuser && <Link
                  to="/superuser/business-card-order"
                  className={`dropdown-item ${
                    isActive("/superuser/business-card-order") ? "active" : ""
                  }`}
                > Business Card Order Management
       </Link>}
              </div>
            </li>}
            {authenticated && <li className="nav-item dropdown">
            {/* eslint-disable-next-line */}
              <a
                className="nav-link-management nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Settings
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                {authorized && (
                  <Link to="/account" className="dropdown-item">
                    Account Settings
                  </Link>
                )}
                <Link to={`/${authorized ? "logout" : "login"}`} className="dropdown-item">
                  {authorized ? "Logout" : "Login"}
                </Link>
              </div>
            </li>}
          </ul>
        </div>
        <Link
          to={`/${authorized ? "logout" : "login"}`}
          onClick={handleLoginClick}
          className={`nav-link-management  login-icon ${isNavbarOpen ? "visible" : ""}`}
          aria-label={authenticated ? "Logout" : "Driver Login"}
        >
          {authorized ? "Logout" : <FaUserCircle size={30} />}
        </Link>
      </nav>

      <div onClick={() => {setMessage({})}} className="message">
        {messageContent}
      </div>

      <Routes>
        {/* Public */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/home" element={<Dashboard />} />
        <Route path="/ride-with-us" element={<RideWithUs />} />
        <Route path="/new-booking" element={<BookATrip />} />
        <Route path="/share-a-ride" element={<ShareARide />} />
        <Route path="/booking" element={<ManageMyBooking />} />
        <Route path="/share-a-ride/booking" element={<ManageMyBooking />} />

        {/* Private - With Authenitcation */}
        <Route path="/login" element={<Login setAuth={setAuthenticated} />} />
        <Route path="/register" element={<Login setAuth={setAuthenticated} tab="register"/>} />
        <Route path="/logout" element={<Login setAuth={setAuthenticated} />} />
        <Route path="/dashboard" element={<DashboardWithAuthentication />} />
        <Route path="/account" element={<AccountSettingsWithAuthentication />}/>
        <Route path="/addresses" element={<AddressPageWithAuthentication />} />
        <Route path="/contacts" element={<ContactsWithAuthentication />} />

        {/* Private - With Authorisation */}
        <Route path="/client/client-management" element={<ClientManagementWithAuthorization requiredAuthority={Authority.ROLE_BACKOFFICE}/>} />
        <Route path="/client/fleet-management" element={<FleetManagementWithAuthorization requiredAuthority={Authority.ROLE_BACKOFFICE}/>} />
        <Route path="/client/tariff-management" element={<TariffManagementWithAuthorization requiredAuthority={Authority.ROLE_BACKOFFICE}/>} />
        <Route path="/client/ride-management" element={<ClientBookingsWithAuthorization requiredAuthority={Authority.ROLE_BACKOFFICE}/>} />
        <Route path="/client/business-card-order" element={<BusinessCardOrdersWithAuthorization requiredAuthority={Authority.ROLE_BACKOFFICE}/>} />
        <Route path="/superuser/business-card-order" element={<BusinessCardOrdersWithAuthorization requiredAuthority={Authority.ROLE_SUPERUSER}/>} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
            {/* Footer */}
      <div className="footer">
        &copy; {new Date().getFullYear()} Transfero. All Rights Reserved. A project by <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/sofetech">Sofetech Ltd.</a>
      </div>
    </div>
  );
};

export default Navbar;
